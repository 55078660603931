import { FC, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { FormikHelpers } from "formik";
import {
  Topbar,
  AddAccountForm,
  DeleteConfirmationModal,
  types,
  toast,
  ReactTable,
  useDispatchOnParams,
  utils
} from "@vilocnv/allsetra-core";

// Data
import { useAppDispatch, useAppSelector } from "hooks";
import {
  selectAccountsState,
  selectDrawerSelectedAccountId
} from "app/data/selectors";
import {
  activateAccountThunk,
  deactivateAccountThunk,
  createAccountThunk,
  postAccountsMinimalSearch
} from "app/features";
import { ACCOUNTS_LINEAR_TABLE_COLUMNS } from "app/data/constants";
import { signalRGenerateSuccessToastMessage } from "app/data/helpers";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { SignalRService } from "app/data/services";

const Accounts: FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  // Global State
  const { selectedAccounts, loading, totalAccountsCount } =
    useAppSelector(selectAccountsState);
  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);

  // Local State
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(
    null
  );
  const [open, setOpen] = useState(false); // Boolean state for AddaccountForm Modal
  const [openDeleteModal, setOpenDeleteModal] = useState(false); // Boolean state for DeleteConfirmationModal Modal
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { t, i18n } = useTranslation([
    "translation",
    "tableHeadingsTranslation",
    "formFieldsTranslation"
  ]);

  const tableColumns = useMemo(
    () => ACCOUNTS_LINEAR_TABLE_COLUMNS(t),
    [t, i18n.language]
  );

  useDispatchOnParams(postAccountsMinimalSearch);

  const handleActivateAccount = async (account: any) => {
    if (account) {
      const { type } = await dispatch(activateAccountThunk(account.uniqueId));

      if (type === "accounts/activateAccountThunk/fulfilled") {
        dispatch(postAccountsMinimalSearch(utils.getCommonParamsForApi()));
      }
    }
  };

  const openDeleteConfirmationModal = (account: any) => {
    setSelectedAccountId(account.uniqueId);
    setOpenDeleteModal(true);
  };

  const onDeactivatedFulfilled = () => {
    setIsSubmitting(false);
    setOpenDeleteModal(false);
    toast.success("Account has been deactivated successfully.");
    dispatch(postAccountsMinimalSearch(utils.getCommonParamsForApi()));
  };

  const handleDeactivateAccount = async () => {
    if (isEmpty(selectedAccountId)) {
      toast.error("Kindly select an account firstly.");
    }

    setIsSubmitting(true);

    const { type } = await dispatch(
      deactivateAccountThunk(selectedAccountId ?? "")
    );

    if (type === "accounts/deactivateAccountThunk/fulfilled") {
      // Set a timeout to stop listening after 10 seconds
      const timeoutId = setTimeout(() => {
        SignalRService.hubConnection?.off("EventRaised");
        onDeactivatedFulfilled();
        console.log("Stopped listening for EventRaised after 10 seconds.");
      }, 10000);

      const handleEventRaised = (event: any) => {
        if (
          event.eventName === types.BackendEventsEnum.AccountDeactivatedEvent
        ) {
          onDeactivatedFulfilled();

          // Clear the timeout if the event is received
          clearTimeout(timeoutId);

          // Remove the listener if it's a one-time use
          SignalRService.hubConnection?.off("EventRaised", handleEventRaised);
        }
      };

      SignalRService.hubConnection?.on("EventRaised", handleEventRaised);
    } else {
      setIsSubmitting(false);
    }
  };

  const handleAccountSettings = (account: any) => {
    navigate(`/dashboard/accounts/settings/${account.uniqueId}`);
  };

  const onCreatedFulfilled = (
    accountName: string,
    formikHelpers: FormikHelpers<types.IAddAccount>
  ) => {
    setOpen(false);
    formikHelpers.setSubmitting(false);
    setIsSubmitting(false);
    toast.success(
      signalRGenerateSuccessToastMessage(accountName, "Account", "created")
    );
    dispatch(postAccountsMinimalSearch(utils.getCommonParamsForApi()));
  };

  const addAccountHandler = async (
    values: types.IAddAccount,
    formikHelpers: FormikHelpers<types.IAddAccount>
  ) => {
    formikHelpers.setSubmitting(true);
    setIsSubmitting(true);

    const { type } = await dispatch(
      createAccountThunk({ accountId: drawerSelectedAccountId, data: values })
    );

    if (type === "accounts/createAccountThunk/fulfilled") {
      // Set a timeout to stop listening after 10 seconds
      const timeoutId = setTimeout(() => {
        SignalRService.hubConnection?.off("EventRaised");
        onCreatedFulfilled(values.name, formikHelpers);
        console.log("Stopped listening for EventRaised after 10 seconds.");
      }, 10000);

      const handleEventRaised = (event: any) => {
        if (event.eventName === types.BackendEventsEnum.AccountCreatedEvent) {
          onCreatedFulfilled(values.name, formikHelpers);

          // Clear the timeout if the event is received
          clearTimeout(timeoutId);

          // Remove the listener if it's a one-time use
          SignalRService.hubConnection?.off("EventRaised", handleEventRaised);
        }
      };

      SignalRService.hubConnection?.on("EventRaised", handleEventRaised);
    } else {
      formikHelpers.setSubmitting(false);
      setIsSubmitting(false);
    }
  };

  return (
    <Box>
      <Topbar
        theme={theme}
        title={t("drawerMenuLinks.accounts")}
        primaryButton={{
          id: "account-add",
          variant: "outlined",
          text: t("titles.addAccount"),
          startIcon: <AddIcon />,
          onClick: () => setOpen(true)
        }}
      />
      <Box mx={4}>
        <ReactTable
          columns={tableColumns}
          data={selectedAccounts}
          isSelectable={false}
          isLoading={loading as any}
          paginationTotalRows={totalAccountsCount as any}
          sx={undefined}
          moduleName="account"
          tableMainBoxStyles={{
            height: "calc(100vh - 314px)",
            border: "1px solid #D5D9E8",
            borderRadius: "10px",
            marginBottom: "10vh",
            padding: "1px"
          }}
          mainTableStyles={{}}
          searchFieldPlaceholder={t("common.search")}
          // sortServer={true}
          // sortableColumns={["name", "status"]}
          cellActions={[
            {
              name: t("typography.accountSettings"),
              onClick: handleAccountSettings
            },
            {
              name: t("typography.activateAccount"),
              onClick: handleActivateAccount
            },
            {
              name: t("typography.deactivateAccount"),
              onClick: openDeleteConfirmationModal
            }
          ]}
          translator={t}
        />
      </Box>
      <AddAccountForm
        open={open}
        onClose={() => setOpen(false)}
        theme={theme}
        onSubmit={addAccountHandler}
        submitting={isSubmitting}
        translator={t}
      />
      <DeleteConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title={t("typography.deactivateAccountTitle")}
        subTitle={t("typography.deactivateAccountSubtitle")}
        theme={theme}
        primaryBtnProps={{
          onClick: handleDeactivateAccount,
          loading: isSubmitting,
          text: t("buttonsText.delete")
        }}
        secondaryBtnProps={{
          text: t("buttonsText.cancel")
        }}
      />
    </Box>
  );
};

export default Accounts;
