import * as Yup from "yup";
import { GroupBy, getDateKey } from "../reportCommonHelpers";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { WorkingHoursData } from "app/data/types";
import moment from "moment";
import { sortBy } from "lodash";
import { calculateDeltaSum } from "app/data/constants";

export const reportsWorkingHoursFormInitialValues = {
  objectType: [],
  group: [],
  object: [],
  key: [],
  daysOfWeek: [],
  startTime: null,
  endTime: null
};

export const reportsWorkingHoursFormValidationSchema: Yup.Schema = Yup.object({
  objectType: Yup.array().of(Yup.string()).label("Object Types"),
  group: Yup.array().of(Yup.string()).label("Groups"),
  object: Yup.array().of(Yup.string()).label("Objects"),
  key: Yup.array().of(Yup.string()).label("Key")
});

export const formattedWorkingHours = (
  data: any,
  groupBy: GroupBy
): WorkingHoursData[] => {
  const formattedData: WorkingHoursData[] = [];

  data?.forEach((item: any) => {
    item?.groupedInfo?.forEach((groupedItem: any) => {
      const serialNumber = groupedItem.serialNumber;

      const existingFormattedDataIndex = formattedData.findIndex(
        (formattedItem) =>
          formattedItem?.groupedInfo?.serialNumber === serialNumber
      );

      if (existingFormattedDataIndex === -1) {
        const newFormattedData: WorkingHoursData = {
          groupedInfo: {
            name: groupedItem.name,
            contactHours: groupedItem.contactHours,
            operatingHours: groupedItem.operatingHours,
            ptoHours: groupedItem.ptoHours,
            serialNumber: groupedItem.serialNumber
          },
          details: []
        };

        formattedData.push(newFormattedData);
      } else {
        formattedData[existingFormattedDataIndex].groupedInfo.contactHours +=
          groupedItem.contactHours;
        formattedData[existingFormattedDataIndex].groupedInfo.operatingHours +=
          groupedItem.operatingHours;
        formattedData[existingFormattedDataIndex].groupedInfo.ptoHours +=
          groupedItem.ptoHours;
      }

      const currentFormattedDataIndex = formattedData.findIndex(
        (formattedItem) =>
          formattedItem?.groupedInfo?.serialNumber === serialNumber
      );

      if (currentFormattedDataIndex !== -1) {
        item.detailedInfo.forEach((detail: any) => {
          if (detail.serialNumber === serialNumber && detail.end !== null) {
            const date = getDateKey(detail.start, groupBy);

            const existingDateRecordIndex = formattedData[
              currentFormattedDataIndex
            ].details.findIndex((record) => record.date === date);

            if (existingDateRecordIndex === -1) {
              formattedData[currentFormattedDataIndex].details.push({
                date,
                data: []
              });
            }

            const currentDetailData = formattedData[
              currentFormattedDataIndex
            ].details.find((record) => record.date === date);

            if (currentDetailData) {
              const existingDetailIndex = currentDetailData.data.findIndex(
                (existingDetail) =>
                  existingDetail.sessionId === detail.sessionId
              );

              if (existingDetailIndex !== -1) {
                // If detail with the same sessionId already exists, update it
                currentDetailData.data[existingDetailIndex] = { ...detail };
              } else {
                // If detail with the same sessionId doesn't exist, add it
                currentDetailData.data.push({ ...detail });
              }
            }
          }
        });

        formattedData[currentFormattedDataIndex].details.sort((a, b) => {
          return new Date(a.date).getTime() - new Date(b.date).getTime();
        });
      }
    });
  });

  return sortBy(formattedData, ["groupedInfo.name"]);
};

const workingHoursPDFextractedRows = (items: any) => {
  return items.map((item: any) => {
    return [
      item?.name,
      item?.sessionId,
      moment(item?.start).format("DD/MM/YY HH:mm:ss") || "N/A",
      moment(item?.end).format("DD/MM/YY HH:mm:ss") || "N/A",
      item?.duration,
      item?.startAddress,
      item?.endAddress,
      item?.startValue?.toFixed(1),
      item?.endValue?.toFixed(1) || "N/A",
      item?.calculatedDelta?.toFixed(1) || "N/A",
      getSessionTypeLabel(item?.sessionType)
    ];
  });
};

export const generateFormattedWorkingHoursPdf = (
  doc: jsPDF,
  report: any[],
  columns: string[]
) => {
  let printSummary = true;
  report.forEach((groupedInfo: any, index: number) => {
    const { name, serialNumber, contactHours, operatingHours, ptoHours } =
      groupedInfo.groupedInfo;
    const details = groupedInfo.details;
    const startDate = details[0].date;
    const endDate = details[details.length - 1].date;

    if (index > 0) {
      doc.addPage();
    }
    doc.setFontSize(10);

    //print summary on the start of each object
    if (printSummary) {
      doc.setFontSize(11);
      doc.text(`Name: ${name}`, 65, index === 0 ? 25 : 15);
      doc.text(`Serial Number: ${serialNumber}`, 65, index === 0 ? 30 : 20);
      doc.text(`Start Date: ${startDate}`, 65, index === 0 ? 35 : 25);
      doc.text(`End Date: ${endDate}`, 65, index === 0 ? 40 : 30);
      doc.text(
        `Total Ignition Hours: ${contactHours?.toFixed(1)}`,
        65,
        index === 0 ? 45 : 35
      );
      doc.text(
        `Total Running Hours: ${operatingHours?.toFixed(1)}`,
        65,
        index === 0 ? 50 : 40
      );
      doc.text(
        `Total PTO Hours: ${ptoHours?.toFixed(1)}`,
        65,
        index === 0 ? 55 : 45
      );
      doc.setFontSize(10);
    }

    let previousDate: any = null;

    details.forEach(({ date, data }: any, i: number) => {
      if (date !== previousDate && previousDate !== null) {
        doc.addPage();
      }

      previousDate = date;

      doc.text(`Date: ${date}`, 10, index === 0 ? 25 : 15);
      doc.text(
        `Week number: ${moment(date).week()}`,
        10,
        index === 0 ? 30 : 20
      );
      doc.text(`Name: ${name}`, 10, index === 0 ? 35 : 25);
      doc.text(calculateDeltaSum(data, 2) || "", 10, index === 0 ? 40 : 30);
      doc.text(calculateDeltaSum(data, 1) || "", 10, index === 0 ? 45 : 35);
      doc.text(calculateDeltaSum(data, 3) || "", 10, index === 0 ? 50 : 40);

      const compiledData = data.map((item: any) => ({
        ...item,
        date
      }));

      autoTable(doc, {
        head: [columns],
        body: workingHoursPDFextractedRows(compiledData),
        startY: printSummary && index === 0 ? 65 : 60,
        styles: { overflow: "linebreak" },
        columnStyles: {
          2: { cellWidth: 25 },
          3: { cellWidth: 25 }
        }
      });
      printSummary = false;
    });
    printSummary = true;
  });
};

export const getSessionTypeLabel = (sessionType: number) => {
  switch (sessionType) {
    case 1:
      return "Running Hours";
    case 2:
      return "Ignition Hours";
    case 3:
      return "PTO Hours";
    default:
      return "-";
  }
};
