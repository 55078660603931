import { FC } from "react";
import { Box, Stack, useTheme } from "@mui/material";
import {
  AccordionLayout,
  FormikInputField,
  FormikSelectField,
  TabPanel,
  TabPanes,
  TwoColsLayout
} from "@vilocnv/allsetra-core";
import {
  InformationFormContainer,
  InformationTabHeading,
  InformationTabsContainer
} from "../../activationPortalForms.styled";
import AlarmContactPersonsForm from "./AlarmContactPersonsForm";
import { FieldArray } from "formik";
import PhoneInput from "components/ui/inputs/PhoneInput/PhoneInput";

interface Props {
  securityQuestions: Array<any>;
  tabSelectedIndex: number;
  setTabSelectedIndex: (value: number) => void;
  translator: any;
  countryListLoading: boolean;
  countries: Array<any>;
  setFieldValue: any;
  europeanCountryCodes?: { value: string }[];
}

const InnerForm: FC<Props> = ({
  securityQuestions,
  tabSelectedIndex,
  setTabSelectedIndex,
  translator,
  countryListLoading,
  countries,
  setFieldValue
}) => {
  const theme = useTheme();

  return (
    <InformationFormContainer>
      <Stack spacing={5}>
        <InformationTabsContainer>
          <InformationTabHeading>
            {translator("headings.orderType", {
              ns: "activationPortal"
            })}
          </InformationTabHeading>
          <TabPanes
            value={tabSelectedIndex}
            onChange={(tab) => {
              setTabSelectedIndex(tab);
              if (tab === 1) setFieldValue("companyInfo.name", "");
              else setFieldValue("companyInfo.name", "-");
            }}
            headings={[
              translator("headings.private", {
                ns: "activationPortal"
              }),
              translator("headings.business", {
                ns: "activationPortal"
              })
            ]}
          >
            <TabPanel value={tabSelectedIndex} index={0}></TabPanel>
            <TabPanel value={tabSelectedIndex} index={1}></TabPanel>
          </TabPanes>
        </InformationTabsContainer>
        <Stack spacing={0}>
          {tabSelectedIndex === 1 && (
            <AccordionLayout
              title={translator("titles.companyInformation", {
                ns: "activationPortal"
              })}
              expand={true}
            >
              <Stack spacing={3}>
                <TwoColsLayout hideDivider>
                  <FormikInputField
                    theme={theme}
                    label={translator("companyName.label", {
                      ns: "formFieldsTranslation"
                    })}
                    name="companyInfo.name"
                    fullWidth
                    required
                    autoComplete="off"
                    debounceDelay={0}
                  />
                  <FormikInputField
                    theme={theme}
                    label={translator("street.label", {
                      ns: "formFieldsTranslation"
                    })}
                    name="companyInfo.street"
                    fullWidth
                    required
                    autoComplete="off"
                    debounceDelay={0}
                  />
                </TwoColsLayout>

                <TwoColsLayout hideDivider>
                  <TwoColsLayout hideDivider>
                    <FormikInputField
                      theme={theme}
                      label={translator("houseNumber.label", {
                        ns: "formFieldsTranslation"
                      })}
                      name="companyInfo.number"
                      fullWidth
                      required
                      autoComplete="off"
                      debounceDelay={0}
                    />
                    <FormikInputField
                      theme={theme}
                      label={translator("extension.label", {
                        ns: "formFieldsTranslation"
                      })}
                      name="companyInfo.extension"
                      fullWidth
                      autoComplete="off"
                      debounceDelay={0}
                    />
                  </TwoColsLayout>
                  <FormikInputField
                    theme={theme}
                    label={translator("postalCode.label", {
                      ns: "formFieldsTranslation"
                    })}
                    name="companyInfo.postCode"
                    fullWidth
                    required
                    autoComplete="off"
                    debounceDelay={0}
                  />
                </TwoColsLayout>

                <TwoColsLayout hideDivider>
                  <FormikInputField
                    theme={theme}
                    label={translator("city.label", {
                      ns: "formFieldsTranslation"
                    })}
                    name="companyInfo.city"
                    fullWidth
                    required
                    autoComplete="off"
                    debounceDelay={0}
                  />
                  <FormikSelectField
                    theme={theme}
                    label={translator("country.label", {
                      ns: "formFieldsTranslation"
                    })}
                    name="companyInfo.country"
                    options={countries}
                    loading={countryListLoading}
                    optionLabelKey="name"
                    optionValueKey="name"
                    fullWidth
                    required
                  />
                </TwoColsLayout>

                <TwoColsLayout fullWidth hideDivider>
                  <FormikInputField
                    theme={theme}
                    label={translator("vatNumber.label", {
                      ns: "formFieldsTranslation"
                    })}
                    name="companyInfo.vatNumber"
                    fullWidth
                    autoComplete="off"
                    debounceDelay={0}
                  />
                </TwoColsLayout>
              </Stack>
            </AccordionLayout>
          )}

          <AccordionLayout
            title={translator("titles.userInformation", {
              ns: "activationPortal"
            })}
            expand={true}
          >
            <Box mb={3}>
              {translator("titles.userInformationSubtitle", {
                ns: "activationPortal"
              })}
            </Box>
            <Stack spacing={3}>
              <TwoColsLayout hideDivider>
                <FormikSelectField
                  theme={theme}
                  label={translator("prefix.label", {
                    ns: "formFieldsTranslation"
                  })}
                  name="userInfo.prefix"
                  options={[
                    { key: 1, value: "Mr." },
                    { key: 2, value: "Mrs." }
                  ]}
                  optionLabelKey="value"
                  optionValueKey="value"
                  fullWidth
                />
                <FormikInputField
                  theme={theme}
                  label={translator("firstName.label", {
                    ns: "formFieldsTranslation"
                  })}
                  name="userInfo.firstName"
                  fullWidth
                  required
                  autoComplete="off"
                  debounceDelay={0}
                />
              </TwoColsLayout>

              <TwoColsLayout hideDivider>
                <FormikInputField
                  theme={theme}
                  label={translator("lastName.label", {
                    ns: "formFieldsTranslation"
                  })}
                  name="userInfo.lastName"
                  fullWidth
                  required
                  autoComplete="off"
                  debounceDelay={0}
                />
                <FormikInputField
                  theme={theme}
                  label={translator("email.label", {
                    ns: "formFieldsTranslation"
                  })}
                  name="userInfo.email"
                  fullWidth
                  required
                  autoComplete="off"
                  debounceDelay={0}
                />
              </TwoColsLayout>
              <TwoColsLayout hideDivider>
                <TwoColsLayout hideDivider>
                  <FormikInputField
                    theme={theme}
                    label={translator("houseNumber.label", {
                      ns: "formFieldsTranslation"
                    })}
                    required
                    name="companyInfo.number"
                    fullWidth
                    autoComplete="off"
                    debounceDelay={0}
                  />
                  <FormikInputField
                    theme={theme}
                    label={translator("extension.label", {
                      ns: "formFieldsTranslation"
                    })}
                    name="companyInfo.extension"
                    fullWidth
                    autoComplete="off"
                    debounceDelay={0}
                  />
                </TwoColsLayout>
                <FormikInputField
                  theme={theme}
                  label={translator("street.label", {
                    ns: "formFieldsTranslation"
                  })}
                  name="companyInfo.street"
                  required
                  fullWidth
                  autoComplete="off"
                  debounceDelay={0}
                />
              </TwoColsLayout>
              <TwoColsLayout hideDivider>
                <FormikInputField
                  theme={theme}
                  label={translator("postalCode.label", {
                    ns: "formFieldsTranslation"
                  })}
                  name="companyInfo.postCode"
                  required
                  fullWidth
                  autoComplete="off"
                  debounceDelay={0}
                />
                <FormikInputField
                  theme={theme}
                  label={translator("city.label", {
                    ns: "formFieldsTranslation"
                  })}
                  name="companyInfo.city"
                  required
                  fullWidth
                  autoComplete="off"
                  debounceDelay={0}
                />
              </TwoColsLayout>

              <FormikSelectField
                theme={theme}
                label={`${translator("country.label", {
                  ns: "formFieldsTranslation"
                })}`}
                name="companyInfo.country"
                options={countries}
                loading={countryListLoading}
                optionLabelKey="name"
                optionValueKey="name"
                fullWidth
                required
              />

              <TwoColsLayout hideDivider>
                <PhoneInput
                  label={`${translator("phoneNumber.label", {
                    ns: "formFieldsTranslation"
                  })} 1`}
                  translator={translator}
                  phoneInputName="userInfo.phoneNumber1"
                  countryInputName="userInfo.countryCode1"
                  required
                />
                <PhoneInput
                  label={`${translator("phoneNumber.label", {
                    ns: "formFieldsTranslation"
                  })} 2`}
                  translator={translator}
                  phoneInputName="userInfo.phoneNumber2"
                  countryInputName="userInfo.countryCode2"
                />
              </TwoColsLayout>

              <TwoColsLayout hideDivider>
                <FormikSelectField
                  theme={theme}
                  label={translator("securityQuestion.label", {
                    ns: "formFieldsTranslation"
                  })}
                  name="userInfo.safeQuestionId"
                  options={securityQuestions}
                  optionLabelKey="question"
                  optionValueKey="uniqueId"
                  required
                  fullWidth
                />
                <FormikInputField
                  theme={theme}
                  label={translator("securityQuestionResponse.label", {
                    ns: "formFieldsTranslation"
                  })}
                  name="userInfo.safeQuestionAnswer"
                  fullWidth
                  required
                  autoComplete="off"
                  debounceDelay={0}
                />
              </TwoColsLayout>
            </Stack>
          </AccordionLayout>

          <AccordionLayout
            title={translator("titles.alarmContactPersons", {
              ns: "activationPortal"
            })}
          >
            <FieldArray
              name="alarmPersons"
              render={(props: any) => (
                <AlarmContactPersonsForm
                  {...props}
                  securityQuestions={securityQuestions}
                  translator={translator}
                />
              )}
            />
          </AccordionLayout>
        </Stack>
      </Stack>
    </InformationFormContainer>
  );
};

export default InnerForm;
