import { FC, useEffect, useState, useMemo } from "react";
import { Box, useTheme } from "@mui/material";
import {
  ReactTable,
  useDispatchOnParams,
  ActivateKeyForm,
  types,
  DeleteConfirmationModal,
  Topbar,
  toast,
  utils
} from "@vilocnv/allsetra-core";
import { FormikHelpers } from "formik";

// Data
import { useAppDispatch, useAppSelector } from "hooks";
import {
  getAccountKeysByQueryThunk,
  activateKeyThunk,
  deactivateKeyThunk,
  getAvailableKeysByAccountThunk
} from "app/features";
import {
  selectAccountKeysState,
  selectDrawerSelectedAccountState,
  selectAccountAvailableKeysState
} from "app/data/selectors";
import { ACCOUNT_KEYS_TABLE_COLUMNS } from "app/data/constants";
import { useTranslation } from "react-i18next";
import { SignalRService } from "app/data/services";

const KeysManager: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  // Global State
  const { drawerSelectedAccountId: accountId } = useAppSelector(
    selectDrawerSelectedAccountState
  );
  const { accountKeys, totalRecords, loading } = useAppSelector(
    selectAccountKeysState
  );
  const { accountAvailableKeys } = useAppSelector(
    selectAccountAvailableKeysState
  );

  const { t, i18n } = useTranslation([
    "translation",
    "tableHeadingsTranslation",
    "formFieldsTranslation"
  ]);

  const tableColumns = useMemo(
    () => ACCOUNT_KEYS_TABLE_COLUMNS(t),
    [t, i18n.language]
  );

  useEffect(() => {
    if (accountId) {
      dispatch(getAvailableKeysByAccountThunk(accountId || ""));
    }
  }, [accountId]);

  // Local State
  const [searchByValue, setSearchByValue] = useState<string>("keyId");
  const [selectedKeyId, setSelectedKeyId] = useState<string | null>(null);
  const [activateKeyModal, setActivateKeyModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [isSubmittingRequest, setIsSubmittingRequest] =
    useState<boolean>(false);

  useDispatchOnParams(getAccountKeysByQueryThunk, {
    args: { accountId: accountId || "" },
    orderBy: [{ field: "label", ascending: true }],
    searchByField: searchByValue
  });

  const toggleActivateKeyModal = () => setActivateKeyModal(!activateKeyModal);

  const onKeyActivatedFulfilled = async () => {
    setIsSubmittingRequest(false);
    toggleActivateKeyModal();

    toast.success("Key has been activated");

    await dispatch(
      getAccountKeysByQueryThunk({
        accountId: accountId || "",
        params: utils.getCommonParamsForApi()
      })
    );
  };

  const onActivateKeyHandler = async (
    values: types.IActivateKey,
    formikHelpers: FormikHelpers<types.IActivateKey>
  ) => {
    formikHelpers.setSubmitting(true);
    setIsSubmittingRequest(true);

    const { type } = await dispatch(
      activateKeyThunk({
        accountId: accountId,
        keyId: values.keyId,
        label: values.label
      })
    );

    if (type === "accounts/activateKeyThunk/fulfilled") {
      // Set a timeout to stop listening after 10 seconds
      const timeoutId = setTimeout(() => {
        SignalRService.hubConnection?.off("EventRaised");
        onKeyActivatedFulfilled();
        console.log("Stopped listening for EventRaised after 10 seconds.");
      }, 10000);

      const handleEventRaised = (event: any) => {
        if (
          event.eventName === types.BackendEventsEnum.AccountKeyActivatedEvent
        ) {
          onKeyActivatedFulfilled();

          // Clear the timeout if the event is received
          clearTimeout(timeoutId);

          // Remove the listener if it's a one-time use
          SignalRService.hubConnection?.off("EventRaised", handleEventRaised);
        }
      };

      SignalRService.hubConnection?.on("EventRaised", handleEventRaised);
    } else {
      formikHelpers.setSubmitting(false);
      setIsSubmittingRequest(false);
    }
  };

  const onKeyDeactivatedFulfilled = async () => {
    setIsSubmittingRequest(false);
    setOpenDeleteModal(false);
    toast.success("Key has been deactivated");

    await dispatch(
      getAccountKeysByQueryThunk({
        accountId: accountId || "",
        params: utils.getCommonParamsForApi()
      })
    );
  };

  const onDeactivateKeyHandler = async () => {
    setIsSubmittingRequest(true);

    const { type } = await dispatch(
      deactivateKeyThunk({
        accountId: accountId,
        keyId: selectedKeyId
      })
    );

    if (type === "accounts/deactivateKeyThunk/fulfilled") {
      // Set a timeout to stop listening after 10 seconds
      const timeoutId = setTimeout(() => {
        SignalRService.hubConnection?.off("EventRaised");
        onKeyDeactivatedFulfilled();
        console.log("Stopped listening for EventRaised after 10 seconds.");
      }, 10000);

      const handleEventRaised = (event: any) => {
        if (
          event.eventName === types.BackendEventsEnum.AccountKeyDeactivatedEvent
        ) {
          onKeyDeactivatedFulfilled();

          // Clear the timeout if the event is received
          clearTimeout(timeoutId);

          // Remove the listener if it's a one-time use
          SignalRService.hubConnection?.off("EventRaised", handleEventRaised);
        }
      };

      SignalRService.hubConnection?.on("EventRaised", handleEventRaised);
    } else {
      setIsSubmittingRequest(false);
    }
  };

  return (
    <main>
      <Topbar theme={theme} title={t("drawerMenuLinks.keysManager")} />
      <Box mx={4}>
        <ReactTable
          columns={tableColumns}
          data={accountKeys ?? []}
          isSelectable={false}
          isLoading={loading as any}
          paginationTotalRows={totalRecords as any}
          sx={undefined}
          moduleName="key"
          tableMainBoxStyles={{
            height: "calc(100vh - 314px)",
            border: "1px solid #D5D9E8",
            borderRadius: "10px",
            marginBottom: "10vh",
            padding: "1px"
          }}
          mainTableStyles={{}}
          searchFieldPlaceholder={t("common.search")}
          // sortServer={true}
          // sortableColumns={["label", "keyId"]}
          cellActions={[
            {
              name: `${t("common.key")} ${t("buttonsText.deactivate")}`,
              onClick: (key: types.IKey) => {
                setIsSubmittingRequest(false);
                setSelectedKeyId(key.uniqueId);
                setOpenDeleteModal(true);
              },
              when: (key: types.IKey) => key.isActive
            }
          ]}
          translator={t}
          primaryButton={{
            id: "keys-activate-form",
            text: t("buttonsText.activateNewKeys"),
            variant: "outlined",
            theme,
            onClick: toggleActivateKeyModal
          }}
          searchByOptions={[
            {
              value: "keyId",
              label: t("tableHeading.keyID", {
                ns: "tableHeadingsTranslation"
              })
            },
            {
              value: "label",
              label: t("tableHeading.label", {
                ns: "tableHeadingsTranslation"
              })
            }
          ]}
          searchByValue={searchByValue}
          setSearchByValue={setSearchByValue}
        />
        <ActivateKeyForm
          open={activateKeyModal}
          onClose={toggleActivateKeyModal}
          onSubmit={onActivateKeyHandler}
          keys={accountAvailableKeys ?? []}
          submitting={isSubmittingRequest}
          theme={theme}
          translator={t}
        />
        <DeleteConfirmationModal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          title={t("typography.deleteKeyTitle")}
          subTitle={t("typography.deleteKeySubtitle")}
          primaryBtnProps={{
            id: "keys-deactivate",
            text: t("buttonsText.delete"),
            onClick: onDeactivateKeyHandler,
            loading: isSubmittingRequest
          }}
          secondaryBtnProps={{
            text: t("buttonsText.cancel")
          }}
          theme={theme}
        />
      </Box>
    </main>
  );
};

export default KeysManager;
