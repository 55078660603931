import { Box } from "@mui/material";
import { Badge, TableColumn } from "@vilocnv/allsetra-core";
import { formatTimeWithDay } from "app/data/helpers";
import { round } from "lodash";

export const COMMON_REPORT_TABLE_COLUMNS = (
  translator: any,
  isShowKeyCol: boolean
): TableColumn<any>[] => {
  let columns = [
    {
      name: translator("tableHeading.rideType", {
        ns: "tableHeadingsTranslation"
      })
    },
    {
      name: translator("tableHeading.distance", {
        ns: "tableHeadingsTranslation"
      })
    },
    {
      name: translator("tableHeading.rideDuration", {
        ns: "tableHeadingsTranslation"
      })
    }
  ];
  if (isShowKeyCol) {
    columns = [
      {
        name: translator("tableHeading.key", { ns: "tableHeadingsTranslation" })
      },
      ...columns
    ];
  }
  return columns;
};

export const RIDE_MODE_IDENTIFICATION = [
  { label: "None", key: 0 },
  { label: "Business ride", key: 1 },
  { label: "Private ride", key: 2 },
  { label: "Commuting ride", key: 3 }
];

export const getColorForRideMode = (rideMode: number) => {
  const mode = RIDE_MODE_IDENTIFICATION.find((item) => item.key === rideMode);
  switch (mode?.key) {
    case 1:
      return "purple"; // Business ride
    case 2:
      return "info"; // Private ride
    case 3:
      return "success"; // Commuting ride
    case 0:
      return "grey";
    default:
      return "grey"; // Default color for unknown ride mode
  }
};

export const calculateCO2AndMilageTimeDiff = (endTime: any, startTime: any) => {
  const endTimeObj = new Date(endTime);
  const startTimeObj = new Date(startTime);

  const timeDifference = endTimeObj.getTime() - startTimeObj.getTime();

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  const padWithZero = (value: number) => (value < 10 ? `0${value}` : value);

  // Format the output with leading zeros
  const formattedTime = `${padWithZero(hours)}:${padWithZero(minutes)}:${padWithZero(seconds)}`;

  return formattedTime;
};

export const COMMON_REPORT_INNER_TABLE_COLUMNS = (
  translator?: any,
  isShowKeyCol?: boolean
) => {
  let innerColumns = [
    {
      cell: (row: any) => {
        if (row.isCorrection) {
          return (
            <Box
              sx={{
                backgroundColor: "#323946",
                border: "1px solid #76828F",
                borderRadius: "4px",
                padding: "4px 8px",
                color: "#FFFFFF",
                fontSize: "11px",
                fontWeight: 600
              }}
            >
              {translator(`rideMode.correction`)}
            </Box>
          );
        }

        const mode = RIDE_MODE_IDENTIFICATION.find(
          (item) => item.key === row?.rideMode
        );

        const color: any = getColorForRideMode(row.rideMode);

        return mode?.label && mode?.label !== "None" ? (
          <Badge colorScheme={color ?? "error"}>
            {translator
              ? translator(`rideMode.${mode?.label}`)
              : mode?.label || "-"}
          </Badge>
        ) : null;
      }
    },
    {
      cell: (row: any) =>
        row?.kilometersDriven
          ? `${round(row?.kilometersDriven, 1).toString().replace(".", ",")} ${"km"}`
          : "N/A"
    },
    {
      cell: (row: any) => {
        const endTime = formatTimeWithDay(row.endDate);
        const startTime = formatTimeWithDay(row.startDate);

        return endTime !== "Invalid DateTime"
          ? calculateCO2AndMilageTimeDiff(endTime, startTime)
          : "Ongoing";
      }
    }
  ];

  if (isShowKeyCol) {
    innerColumns = [
      {
        cell: (row: any) => row?.key?.label || row?.rawKeyId || "-"
      },
      ...innerColumns
    ];
  }

  return innerColumns;
};

export const RIDE_MODES = (t: any) => {
  return [
    { name: t("rideMode.Business ride").split(" ")[0], value: "1" },
    { name: t("rideMode.Commuting ride").split(" ")[0], value: "3" },
    { name: t("rideMode.Private ride").split(" ")[0], value: "2" }
  ];
};
