import { FC, useEffect, useMemo, useState } from "react";
import { Box, Grid, useTheme } from "@mui/material";
import {
  ChangeGroupForm,
  DeleteConfirmationModal,
  GroupCard,
  toast,
  Topbar,
  types
} from "@vilocnv/allsetra-core";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch, useAppSelector, useSelectSearch } from "hooks";
import {
  selectAccountGroups,
  selectDrawerSelectedAccountId,
  selectQueriedObjectsState
} from "app/data/selectors";
import { GroupsMessageBox } from "./Groups.styled";
import {
  getAllAccountGroupsThunk,
  getAllAccountObjectsThunk,
  removeGroupFromAccountThunk
} from "app/features";
import { useTranslation } from "react-i18next";
import { SignalRService } from "app/data/services";
import { useGroupForm } from "hooks/useGroupForm";

const Groups: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  // Global State
  const { accountGroups } = useAppSelector(selectAccountGroups);

  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);
  const { objects } = useAppSelector(selectQueriedObjectsState);

  const {
    addGroupModal,
    setAddGroupModal,
    editValues,
    setEditValues,
    isSubmitting,
    selectedLocalIds,
    toggleAddGroupModal,
    handleObjectsSearch,
    handleOnChangeGroupForm,
    addGroupHandler
  } = useGroupForm({ drawerSelectedAccountId });

  // Local State
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false); // Boolean state for DeleteConfirmationModal Modal
  const [isDeleting, setIsDeleting] = useState(false);

  const { t } = useTranslation([
    "translation",
    "tableHeadingsTranslation",
    "formFieldsTranslation"
  ]);

  useEffect(() => {
    if (drawerSelectedAccountId !== null) {
      dispatch(getAllAccountGroupsThunk(drawerSelectedAccountId));
      dispatch(getAllAccountObjectsThunk(drawerSelectedAccountId));
    }
  }, [drawerSelectedAccountId]);

  const openEditModal = (item: any) => {
    setEditValues(item);
    setAddGroupModal(true);
    setSelectedGroupId(item.uniqueId);
  };

  const openDeleteConfirmationModal = (groupId: string) => {
    setSelectedGroupId(groupId);
    setOpenDeleteModal(true);
  };

  const onGroupDeletedFulfilled = async () => {
    setOpenDeleteModal(false);
    setIsDeleting(false);
    toast.success("Group has been deleted successfully.");
    await dispatch(getAllAccountGroupsThunk(drawerSelectedAccountId ?? ""));
  };

  const removeGroupHandler = async () => {
    if (!selectedGroupId || !drawerSelectedAccountId) return;

    setIsDeleting(true);

    const { type } = await dispatch(
      removeGroupFromAccountThunk({
        accountId: drawerSelectedAccountId,
        groupId: selectedGroupId
      })
    );

    if (type === "accounts/removeGroupFromAccountThunk/fulfilled") {
      // Set a timeout to stop listening after 10 seconds
      const timeoutId = setTimeout(() => {
        SignalRService.hubConnection?.off("EventRaised");
        onGroupDeletedFulfilled();
        console.log("Stopped listening for EventRaised after 10 seconds.");
      }, 10000);

      const handleEventRaised = (event: any) => {
        if (
          event.eventName ===
          types.BackendEventsEnum.GroupRemovedFromAccountEvent
        ) {
          onGroupDeletedFulfilled();

          // Clear the timeout if the event is received
          clearTimeout(timeoutId);

          // Remove the listener if it's a one-time use
          SignalRService.hubConnection?.off("EventRaised", handleEventRaised);
        }
      };

      SignalRService.hubConnection?.on("EventRaised", handleEventRaised);
    } else {
      setIsDeleting(false);
    }
  };

  const extractObjects = useMemo(() => {
    return selectedGroupId
      ? accountGroups.find(
          (item: { uniqueId: string }) => item.uniqueId === selectedGroupId
        )?.objects || []
      : [];
  }, [selectedGroupId, accountGroups]);

  const selectedLocalObjects = useSelectSearch(selectedLocalIds, objects);

  const formObjects = [...extractObjects, ...selectedLocalObjects];

  return (
    <Box>
      {drawerSelectedAccountId ? (
        <Box>
          <Topbar
            theme={theme}
            title={t("drawerMenuLinks.groups")}
            primaryButton={{
              id: "group-add-form",
              variant: "outlined",
              text: t("titles.addGroup"),
              startIcon: <AddIcon />,
              onClick: toggleAddGroupModal
            }}
          />
          <Box marginLeft={4}>
            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              spacing={{ xs: 2, md: 2 }}
              rowSpacing={{ xs: 4, sm: 6, md: 6 }}
            >
              {accountGroups.map((item, index) => (
                <Grid item key={index}>
                  <GroupCard
                    groupTitle={item.name}
                    onDelete={() => openDeleteConfirmationModal(item.uniqueId)}
                    groupObjects={item.objects}
                    onEdit={() => openEditModal(item)}
                    translator={t}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          <ChangeGroupForm
            open={addGroupModal}
            onClose={toggleAddGroupModal}
            accountId={drawerSelectedAccountId}
            objects={formObjects}
            initialValues={editValues}
            theme={theme}
            onSubmit={addGroupHandler}
            submitting={isSubmitting}
            isCustomerPortal={true}
            translator={t}
            isGroups={true}
            handleDebounce={handleObjectsSearch}
            //@ts-ignore
            onChange={handleOnChangeGroupForm}
          />
          <DeleteConfirmationModal
            open={openDeleteModal}
            onClose={() => setOpenDeleteModal(false)}
            title={t("typography.deleteGroupsTitle")}
            subTitle={t("typography.deleteGroupsSubtitle")}
            primaryBtnProps={{
              id: "group-remove",
              text: t("buttonsText.delete"),
              onClick: removeGroupHandler,
              loading: isDeleting
            }}
            secondaryBtnProps={{
              text: t("buttonsText.cancel")
            }}
            theme={theme}
          />
        </Box>
      ) : (
        <GroupsMessageBox>
          Please select an account to view groups
        </GroupsMessageBox>
      )}
    </Box>
  );
};

export default Groups;
