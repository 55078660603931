import { FC, useEffect, useState, useMemo, useCallback } from "react";
import { Box, Grid, useTheme } from "@mui/material";

// Data
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector, useReportCommonStates } from "hooks";
import {
  convertDatesForFilterPayload,
  convertToNormalTime,
  generateReportFormInitialValues,
  generateReportFormValidationSchema,
  reportTransformTimeForAPI
} from "app/data/helpers";
import { generateReportThunk } from "app/features/reports/reportsActions";
import {
  getAllKeysByAccountThunk,
  resetReports,
  setReportsFilterData
} from "app/features";
import {
  selectAccountFeaturesState,
  selectAccountKeysState
} from "app/data/selectors";
import ReportsMainTopbar from "components/common/reports/ReportsTopbar/ReportsMainTopbar";
import { compact } from "lodash";
import ReportsCard from "components/common/reports/ReportsCard/ReportsCard";
import {
  CO2ReportIcon,
  GraphicalHoursIcon,
  GraphicalRidesIcon,
  MultipleRidesIcon,
  ActivityReportIcon,
  RidesReportIcon,
  MilageReportIcon,
  WorkingHoursReportIcon,
  TagReportIcon
} from "assets/icons";
import GenerateReportForm from "components/forms/Reports/GenerateReportForm";
import { useNavigate } from "react-router-dom";

const DEFAULT_FILTER_VALUES = {
  driver: [],
  group: [],
  object: [],
  objectType: [],
  ridesModes: []
};

const Reports: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { features } = useAppSelector(selectAccountFeaturesState);

  const {
    drawerSelectedAccountId,
    objectTypes,
    objectTypesLoading,
    objectsLoading,
    accountGroups,
    reportsLoading,
    filterOpen,
    setFilterOpen,
    dateValues,
    setDateValues,
    handleObjectsSearch,
    selectedLocalObjects,
    handleOnChangeReportFilterForm,
    preloadedObjects
  } = useReportCommonStates();

  const [filterValues, setFilterValues] = useState<any>(DEFAULT_FILTER_VALUES);

  const [reportType, setReportType] = useState(2);
  const [reportUrl, setReportUrl] = useState("/reports/mileage-registration");

  const { accountKeys, loading: accountKeysLoading } = useAppSelector(
    selectAccountKeysState
  );

  const [apiPayload, setApiPayload] = useState(null);

  const { t } = useTranslation(["translation", "tableHeadingsTranslation"]);

  const handleDateChange = useCallback((value: any) => {
    setDateValues(value);
  }, []);

  const handleDateRangeSubmit = () => {
    const startDate = convertToNormalTime(dateValues.startDate);
    const endDate = convertToNormalTime(dateValues.endDate, true);

    let updatedFilterValues = { ...filterValues };

    if (filterValues.daysOfWeek) {
      updatedFilterValues = {
        ...filterValues,
        daysOfWeek:
          filterValues.daysOfWeek.length === 7
            ? []
            : filterValues.daysOfWeek.map(Number),
        startTime: reportTransformTimeForAPI(
          filterValues.startTime + ":00",
          dateValues.startDate
        ),
        endTime: reportTransformTimeForAPI(
          filterValues.endTime + ":00",
          dateValues.endDate
        )
      };
    }

    const payload = {
      ...(apiPayload ?? {}),
      startDate,
      endDate,
      ...updatedFilterValues,
      reportType: 2,
      rideModes: filterValues?.rideModes?.map(Number) || [],
      startedWithoutIgnition: true
    };

    setApiPayload(payload);
  };

  const handleCardClick = (card: any) => {
    setFilterOpen(true);
    setReportType(card.reportType);
    setReportUrl(card.link);
  };

  const handleLoadReport = async (values: any) => {
    const startDate = new Date(dateValues.startDate);
    const endDate = new Date(dateValues.endDate);

    setFilterValues(values);

    const { formattedStartDate, formattedEndDate } =
      convertDatesForFilterPayload(dateValues);

    const payload = {
      //   ...(apiPayload ?? {}),
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      ...values,
      object: values.assignedToAllObjects ? [] : values.object,
      reportType: reportType, // using local state report Type, default to 2
      daysOfWeek:
        values.daysOfWeek.length === 7 ? [] : values.daysOfWeek.map(Number),
      startTime: reportTransformTimeForAPI(values.startTime + ":00", startDate),
      endTime: reportTransformTimeForAPI(values.endTime + ":00", endDate),
      // rideModes: values?.rideModes?.map(Number) || [],
      startedWithoutIgnition: true
    };

    // dispatch(setReportsFilterData(apiPayload));
    const { type } = await dispatch(generateReportThunk(payload));

    if (type === "reports/generateReportThunk/fulfilled") {
      dispatch(setReportsFilterData(payload));
      navigate(reportUrl);
    }
  };

  useEffect(() => {
    dispatch(resetReports());
    handleDateRangeSubmit();
  }, []);

  useEffect(() => {
    if (drawerSelectedAccountId !== null) {
      dispatch(getAllKeysByAccountThunk(drawerSelectedAccountId));
    }
  }, [drawerSelectedAccountId]);

  const reportsDropdown = useMemo(
    () =>
      compact([
        {
          name: t("reports.activityReport"),
          description: t(
            "reports.landingPage.summarizesTheActivitiesOfTheObjects"
          ),
          icon: <ActivityReportIcon />,
          link: "/dashboard/reports/activity-reports",
          reportType: 6
        },
        {
          name: t("reports.tagPosition"),
          description: t(
            "reports.landingPage.summarizesAllTagsAppliedToRidesOrVehicles"
          ),
          icon: <TagReportIcon />,
          link: "/dashboard/reports/tag-position",
          reportType: 1
        },
        {
          name: t("reports.workingHours"),
          description: t("reports.landingPage.tracksWorkingHoursForTheObjects"),
          icon: <WorkingHoursReportIcon />,
          link: "/dashboard/reports/working-hours",
          reportType: 3
        },
        {
          name: t("reports.graphicalWorkingHours"),
          description: t(
            "reports.landingPage.visualizedWorkingHoursOfTheObjects"
          ),
          icon: <GraphicalHoursIcon />,
          link: "/dashboard/reports/graphical-working-hours",
          reportType: 3
        },
        {
          name: t("reports.co2Report"),
          description: t("reports.landingPage.tracksCo2EmissionsFromRides"),
          icon: <CO2ReportIcon />,
          link: "/dashboard/reports/co2-reports",
          reportType: 4
        },
        ...(features.isRidesEnabled
          ? [
              {
                name: t("reports.graphicalRides"),
                description: t(
                  "reports.landingPage.visualizedRideDataForQuickAnalysis"
                ),
                icon: <GraphicalRidesIcon />,
                link: "/dashboard/reports/graphical-rides",
                reportType: 3
              },
              {
                name: t("reports.mileageRegistration"),
                description: t(
                  "reports.landingPage.vehicleMileageForTrackingAndMaintenancePurposes"
                ),
                icon: <MilageReportIcon />,
                link: "/dashboard/reports/mileage-registration",
                reportType: 2
              }
            ]
          : []),
        {
          name: t("reports.multipleRides"),
          description: t("reports.landingPage.multipleRideRoutesOnAMap"),
          icon: <MultipleRidesIcon />,
          link: "/dashboard/reports/multiple-rides",
          reportType: 5
        }
      ]),
    [t, features.isRidesEnabled]
  );

  return (
    <Box>
      <ReportsMainTopbar title={t("typography.reports")} />
      <Box mx={4}>
        <Grid container spacing={2.5} alignItems={"stretch"}>
          {reportsDropdown?.map((card) => {
            return (
              <Grid
                key={card.name}
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={3}
                alignItems={"stretch"}
              >
                <ReportsCard
                  title={card.name}
                  description={card?.description}
                  icon={card?.icon}
                  onClick={() => handleCardClick(card)}
                />
              </Grid>
            );
          })}
        </Grid>
        <GenerateReportForm
          fetchOnDebounce={handleObjectsSearch}
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
          onSubmit={handleLoadReport}
          groups={accountGroups}
          objectTypes={objectTypes}
          // objects={selectedLocalObjects}
          objects={
            selectedLocalObjects?.length
              ? selectedLocalObjects
              : preloadedObjects
          }
          keys={accountKeys}
          dataLoading={
            objectsLoading || objectTypesLoading || accountKeysLoading
          }
          theme={theme}
          isMileageReport={true}
          //@ts-ignore
          onChange={handleOnChangeReportFilterForm}
          formInitialValues={generateReportFormInitialValues}
          formValidations={generateReportFormValidationSchema}
          size="customSize"
          handleDateChange={handleDateChange}
          dateValues={dateValues}
          dateRangeLoading={reportsLoading}
          reportType={reportType}
        />
      </Box>
    </Box>
  );
};

export default Reports;
