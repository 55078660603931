import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import {
  UnauthenticatedTemplate,
  AuthenticatedTemplate,
  useMsal
} from "@azure/msal-react";
import {
  DashboardLayout,
  // PageLoader,
  SkeletonPageLoader
} from "@vilocnv/allsetra-core";

// Data
import { useTranslation } from "react-i18next";
import {
  useAppDispatch,
  useAppSelector,
  useDispatchOnMount,
  useSearchDrawerAccounts,
  useSetLangOnSettingsChange
} from "hooks";
import {
  getAccountFeaturesThunk,
  getInitialAccountThunk,
  resetAllAccounts,
  resetDashboardState,
  resetReports,
  setDrawerCollapseState,
  setDrawerSelectedAccount,
  setFromDashboard
} from "app/features";
import {
  selectIsDrawerCollapsed,
  selectDrawerSelectedAccountState,
  selectWhiteLabelsState,
  selectAccountFeaturesState,
  selectSearchedMinimalAccountsState
} from "app/data/selectors";
import { getDrawerMenuItems, getDrawerSubMenuLists } from "app/data/constants";
import { isEmpty } from "lodash";
import SignalRService, {
  signalRConnectionEvent
} from "app/data/services/SignalRService";
import { AzureMsal } from "app/data/services";
import { TermsAndConditionsModal } from "components/terms/TermsAndConditionsModal";

export interface ProtectedRouteProps {
  redirectTo: string;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ redirectTo }) => {
  const { pathname, search } = useLocation();
  const { instance } = useMsal();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    searchValue,
    setSearchValue,
    handleSearchChange,
    handleSearchClick,
    handleSearchKeyPress
  } = useSearchDrawerAccounts();

  // Global State
  const isDrawerCollapsed = useAppSelector(selectIsDrawerCollapsed);

  const { drawerSelectedAccountId, drawerSelectedAccountName } = useAppSelector(
    selectDrawerSelectedAccountState
  );

  const { searchedMinimalAccountsLoading, searchedMinimalAccounts } =
    useAppSelector(selectSearchedMinimalAccountsState);

  const { whiteLabel, loading: whiteLabelLoading } = useAppSelector(
    selectWhiteLabelsState
  );

  const { features } = useAppSelector(selectAccountFeaturesState);

  // Local State
  const [renderCycle, setRenderCycle] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { t } = useTranslation();

  useSetLangOnSettingsChange();

  useDispatchOnMount(
    getInitialAccountThunk,
    drawerSelectedAccountId ? undefined : true
  );

  const drawerMenuItems = useMemo(() => getDrawerMenuItems(t), [t]);

  const drawerMenuSubItems = useMemo(
    () => getDrawerSubMenuLists(t, features, drawerSelectedAccountId),
    [t, features, drawerSelectedAccountId]
  );

  const selectedAccount = useMemo(
    () => ({
      id: drawerSelectedAccountId,
      name: drawerSelectedAccountName
    }),
    [drawerSelectedAccountId, drawerSelectedAccountName]
  );

  const getSearchParams = () => {
    const params = new URLSearchParams(window.location.search);
    return {
      uniqueId: params.get("uniqueId"),
      accountName: params.get("accountName")
    };
  };

  useEffect(() => {
    const { accountName: loginAsAccountName, uniqueId: loginAsAccountId } =
      getSearchParams();
    if (!isEmpty(loginAsAccountName) && !isEmpty(loginAsAccountId)) {
      dispatch(
        setDrawerSelectedAccount({
          uniqueId: loginAsAccountId,
          name: loginAsAccountName
        })
      );

      navigate(pathname, { replace: true });
    }

    if (isEmpty(selectedAccount?.id)) {
      dispatch(
        setDrawerSelectedAccount({
          uniqueId: selectedAccount.id,
          name: selectedAccount?.name
        })
      );
    } else {
      dispatch(getAccountFeaturesThunk(selectedAccount?.id ?? ""));
      dispatch(resetReports());
    }
  }, [selectedAccount]);

  const fullPath = `${pathname}${search}`;

  window.onbeforeunload = () => {
    sessionStorage.setItem("oldPathname", fullPath);
  };

  useEffect(() => {
    if (pathname?.split("/dashboard")[1]) {
      sessionStorage.setItem("pathname", fullPath);
    }
  }, [pathname]);

  useEffect(() => {
    dispatch(setFromDashboard(false)); // intentionally setting it to false to remove the login flow from activation portal
    const oldPath = sessionStorage.getItem("oldPathname");

    const _pathname = sessionStorage.getItem("pathname");

    if (_pathname === oldPath) {
      // @ts-ignore
      navigate(_pathname);
    } else {
      return;
    }
  }, []);

  useEffect(() => {
    signalRConnectionEvent.subscribe((value) => {
      if (value === "Connected") {
        setRenderCycle((cycle) => cycle + 1);
      }

      setIsLoading(false);
    });
  }, []);

  const toggleDrawerCollapseState = () => {
    dispatch(setDrawerCollapseState(!isDrawerCollapsed));
  };

  const onAccountChange = useCallback(
    (value: { name: string; uniqueId: string }) => {
      dispatch(setDrawerSelectedAccount(value));
    },
    []
  );

  const handleLogout = useCallback(async () => {
    try {
      await dispatch(setFromDashboard(false));
      await dispatch(resetDashboardState());
      AzureMsal.logoutUser({ showToast: false });
    } catch (error) {
      console.error("Error during logout process:", error);
    }
  }, [instance]);

  return (
    <Fragment>
      <UnauthenticatedTemplate>
        <Navigate to={redirectTo} state={{ from: pathname }} />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate key={renderCycle}>
        {whiteLabelLoading || isLoading ? (
          <SkeletonPageLoader />
        ) : (
          <>
            <TermsAndConditionsModal whiteLabel={whiteLabel} />
            <DashboardLayout
              // @ts-ignore
              size={"large"}
              isDrawerCollapsed={isDrawerCollapsed}
              toggleDrawerCollapseState={toggleDrawerCollapseState}
              appLogoType={"customer"}
              menuList={drawerMenuItems}
              activeLinkTextColor={theme.palette.primary.main}
              activeLinkBgColor={"rgba(245, 255, 254, 0.12)"}
              showAccountsField={true}
              accounts={searchedMinimalAccounts}
              selectedAccount={selectedAccount}
              onAccountChange={onAccountChange}
              isSignalRConnected={SignalRService.hubConnection?.state}
              onSupportClick={() => {}}
              onLogoutClick={handleLogout}
              translator={t}
              whiteLabel={whiteLabel}
              showReportBugForm={isEmpty(whiteLabel)}
              subLists={drawerMenuSubItems}
              newAccountsSearchField={true}
              handleSearchChange={handleSearchChange}
              handleSearchClick={handleSearchClick}
              handleSearchKeyPress={handleSearchKeyPress}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              searchAccountsLoading={searchedMinimalAccountsLoading}
            />
          </>
        )}
      </AuthenticatedTemplate>
    </Fragment>
  );
};

export default ProtectedRoute;
