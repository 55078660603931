import { FC } from "react";
import { Button } from "@vilocnv/allsetra-core";
import { Stack, useTheme } from "@mui/material";
import { ButtonsRow, DaysTitle, LabelRowBox } from "./DaysFilter.styled";
import { useTranslation } from "react-i18next";
import { daysOfWeekOptions } from "app/data/helpers";
import { useFormikContext } from "formik";

interface Props {
  values: any;
}

const DaysFilters: FC<Props> = ({ values }) => {
  const theme = useTheme();
  const { t } = useTranslation(["translation", "formFieldsTranslation"]);
  const { setFieldValue } = useFormikContext();

  const handleDaysChange = (value: string) => {
    const daysSet = new Set(values.daysOfWeek);
    if (daysSet.has(value)) daysSet.delete(value);
    else daysSet.add(value);
    setFieldValue("daysOfWeek", Array.from(daysSet));
  };

  return (
    <Stack spacing={2}>
      <LabelRowBox>
        <DaysTitle>
          {t("selectDays.label", { ns: "formFieldsTranslation" })}
        </DaysTitle>
        <DaysTitle>
          {values.daysOfWeek.length > 0
            ? `${values.daysOfWeek.length} ${
                values.daysOfWeek.length === 1
                  ? t("day", { ns: "formFieldsTranslation" })
                  : t("days", {
                      ns: "formFieldsTranslation"
                    })
              }`
            : t("allDays", { ns: "formFieldsTranslation" })}
        </DaysTitle>
      </LabelRowBox>
      <ButtonsRow gap={0.5}>
        {daysOfWeekOptions.map((day: any) => (
          <Button
            key={`${day.label}`}
            text={t("daysListShort." + day.label)}
            size="small"
            variant="contained"
            theme={theme}
            onClick={() => handleDaysChange(day.value)}
            sx={{
              borderRadius: 1,
              backgroundColor: values?.daysOfWeek?.includes(day.value)
                ? "#00B2A3"
                : "#F5FFFE",
              color: values?.daysOfWeek?.includes(day.value)
                ? "#FFFFFF"
                : "#1D1F2B",
              border: values?.daysOfWeek?.includes(day.value)
                ? "1px solid #00B2A3"
                : "1px solid #A3E7E1",
              "&:hover": {
                color: "#FFFFFF",
                border: "none"
              },
              "&:focus": {
                border: "1px solid #00B2A3"
              }
            }}
          />
        ))}
      </ButtonsRow>
    </Stack>
  );
};

export default DaysFilters;
