import { FC, useEffect, useState } from "react";
import { Box, CircularProgress, useTheme } from "@mui/material";
import { FormikHelpers } from "formik";
import { toast } from "@vilocnv/allsetra-core";
import ReportsTopbar from "components/common/reports/ReportsTopbar/ReportsTopbar";

// Data
import { useAppDispatch, useAppSelector, useReportCommonStates } from "hooks";
import {
  commonReportsDataFormatter,
  multipleRidesReportsFilterFormValidationSchema,
  convertDatesForFilterPayload,
  convertToNormalTime,
  reportsMilageRegistrationFormInitialValues,
  reportTransformTimeForAPI
} from "app/data/helpers";
import { generateReportThunk } from "app/features/reports/reportsActions";
import {
  getAllKeysByAccountThunk,
  resetReports,
  setReportsFilterData
} from "app/features";
import { useTranslation } from "react-i18next";
import ReportsFilterBar from "components/common/reports/ReportFiltersBar/ReportsFilterBar";
import { selectAccountKeysState } from "app/data/selectors";
import ReportsCommonFilterForm from "components/forms/Reports/ReportsCommonFilterForm";

import MultipleRidesGoogleMap from "components/maps/MultipleRidesGoogleMap/MultipleRidesGoogleMap";
import { CenterTextBox } from "./Reports.styled";
import { MapStateProvider } from "components/maps/Map/v2/GoogleMap/MapStateContext";

const DEFAULT_FILTER_VALUES = {
  driver: [],
  group: [],
  object: [],
  objectType: []
};

const MultipleRides: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const {
    drawerSelectedAccountId,
    objectTypes,
    objectTypesLoading,
    objectsLoading,
    accountGroups,
    report,
    reportsLoading,
    filterOpen,
    setFilterOpen,
    dateFormat,
    setDateFormat,
    dateValues,
    setDateValues,
    localReport,
    setLocalReport,
    handleObjectsSearch,
    selectedLocalObjects,
    handleOnChangeReportFilterForm,
    reportsHasExceededLimit,
    preloadedObjects
  } = useReportCommonStates();

  const [filterValues, setFilterValues] = useState<any>(DEFAULT_FILTER_VALUES);
  const [openModal, setOpenModal] = useState(false);

  const { t } = useTranslation(["translation", "tableHeadingsTranslation"]);
  const [apiPayload, setApiPayload] = useState(null);

  const { accountKeys, loading: accountKeysLoading } = useAppSelector(
    selectAccountKeysState
  );

  const multipleRidesFiltersSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true);

    const startDate = new Date(dateValues.startDate);
    const endDate = new Date(dateValues.endDate);

    setFilterValues(values);

    const { formattedStartDate, formattedEndDate } =
      convertDatesForFilterPayload(dateValues);

    const payload = {
      ...(apiPayload ?? {}),
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      ...values,
      reportType: 5,
      daysOfWeek:
        values.daysOfWeek.length === 7 ? [] : values.daysOfWeek.map(Number),
      startTime: reportTransformTimeForAPI(values.startTime + ":00", startDate),
      endTime: reportTransformTimeForAPI(values.endTime + ":00", endDate)
    };

    dispatch(setReportsFilterData(payload));
    setApiPayload(payload);

    formikHelpers.setSubmitting(false);
    setFilterOpen(false);
  };

  const formattedData = commonReportsDataFormatter(
    localReport,
    "name",
    dateFormat
  );

  const handleDateChange = (value: any) => {
    setDateValues(value);
  };

  const handleDateRangeSubmit = () => {
    const startDate = convertToNormalTime(dateValues.startDate);
    const endDate = convertToNormalTime(dateValues.endDate, true);

    let updatedFilterValues = { ...filterValues };

    if (filterValues.daysOfWeek) {
      updatedFilterValues = {
        ...filterValues,
        daysOfWeek:
          filterValues.daysOfWeek.length === 7
            ? []
            : filterValues.daysOfWeek.map(Number),
        startTime: reportTransformTimeForAPI(
          filterValues.startTime + ":00",
          dateValues.startDate
        ),
        endTime: reportTransformTimeForAPI(
          filterValues.endTime + ":00",
          dateValues.endDate
        )
      };
    }

    const payload = {
      ...(apiPayload ?? {}),
      startDate,
      endDate,
      ...updatedFilterValues,
      reportType: 5
    };

    setApiPayload(payload);
  };

  const handleLoadReport = () => {
    dispatch(setReportsFilterData(apiPayload));
    dispatch(generateReportThunk(apiPayload));
  };

  // useEffect(() => {
  //   dispatch(resetReports());
  //   handleDateRangeSubmit();
  // }, []);

  useEffect(() => {
    if (drawerSelectedAccountId !== null) {
      dispatch(getAllKeysByAccountThunk(drawerSelectedAccountId));
      dispatch(resetReports());
    }
  }, [drawerSelectedAccountId]);
  useEffect(() => {
    setLocalReport(report);
  }, [report]);

  useEffect(() => {
    if (report?.length && reportsHasExceededLimit) {
      const groupedRidesItems = report
        .flatMap((entry: any) => {
          return entry.items;
        })
        .reduce((acc: any, item: any) => {
          if (item?.rideLocations?.length > 0) {
            acc[item.rideUniqueId] = (acc[item.rideUniqueId] || 0) + 1;
          }
          return acc;
        }, {});

      const countItems = Object.entries(groupedRidesItems).length;

      if (countItems) {
        toast.info(
          t("reports.rideLimitExceed", {
            count: countItems
          })
        );
      }
    }
  }, [report, reportsHasExceededLimit]);

  return (
    <Box>
      <ReportsTopbar
        dropdownTitle={t("reports.multipleRides")}
        disabled={reportsLoading}
        hideReportHistory={true}
        hideScheduleReports={true}
      />
      <Box mx={4}>
        <ReportsFilterBar
          openModal={openModal}
          setOpenModal={setOpenModal}
          hideExportButton={true}
          hideDateFormats={true}
          setFilterOpen={setFilterOpen}
          dateFormat={dateFormat}
          setDateFormat={setDateFormat}
          handleDateChange={handleDateChange}
          dateValues={dateValues}
          handleDateRangeSubmit={handleDateRangeSubmit}
          dateRangeLoading={reportsLoading}
          disableExportButton={!formattedData?.length}
          reportType={5}
          reportExportTypeValue={2}
          hideScheduleButton
          handleLoadReport={handleLoadReport}
        />
        <ReportsCommonFilterForm
          fetchOnDebounce={handleObjectsSearch}
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
          onSubmit={multipleRidesFiltersSubmitHandler}
          groups={accountGroups}
          objectTypes={objectTypes}
          objects={
            selectedLocalObjects.length
              ? selectedLocalObjects
              : preloadedObjects
          }
          keys={accountKeys}
          dataLoading={
            objectsLoading || objectTypesLoading || accountKeysLoading
          }
          theme={theme}
          //@ts-ignore
          onChange={handleOnChangeReportFilterForm}
          formInitialValues={reportsMilageRegistrationFormInitialValues}
          formValidations={multipleRidesReportsFilterFormValidationSchema}
          isMultipleRidesForm
          size="customSize"
        />
        {reportsLoading && (
          <CenterTextBox>
            <CircularProgress />
          </CenterTextBox>
        )}

        {!reportsLoading && localReport?.length > 0 && (
          <MapStateProvider
            height="calc(100vh - 170px)"
            disableNavigator={true}
            skipCurrentLocation={true}
            showSearch={true}
            showFilter={true}
            isDashboardMap={false}
            objectsMarker={false}
            objects={[]}
            initialZoomLevel={null}
          >
            <MultipleRidesGoogleMap objectsRides={localReport} />
          </MapStateProvider>
        )}

        {!reportsLoading && !localReport?.length && (
          <CenterTextBox>There are no records to display</CenterTextBox>
        )}
      </Box>
    </Box>
  );
};

export default MultipleRides;
