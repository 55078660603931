import { styled, Box, Typography, Stack } from "@mui/material";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";

export const CardContainer = styled(Box)<{
  isActive?: boolean;
}>(({ isActive }) => ({
  padding: "20px",
  borderBottom: "1px solid #DFE5F4",
  background: "#fff",
  backgroundColor: isActive ? "#F5FFFE" : "#FFF",
  "&:hover": {
    backgroundColor: "#F5FFFE",

    "& .link-text": {
      color: "#00B2A3"
    },
    "& .arrow-icon": {
      display: "inline-block"
    }
  }
}));

export const SmallText = styled(Typography)<{
  color?: string;
}>(({ color }) => ({
  color: color || "#646D7B",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "132%"
}));

export const InfoIcon = styled(InfoRoundedIcon)({
  width: "16px",
  height: "16px",
  color: "#959EB2"
});

export const ObjectIconBox = styled(Box)({
  width: "25px",
  height: "25px",
  objectFit: "contain"
});

export const LinkTextBox = styled(Box)({
  // "&:hover .arrow-icon": {
  //   transform: "translate(2px, -2px)"
  // },
  "& .arrow-icon": {
    display: "none"
    // transition: "all 0.2s ease-in-out"
  }
});

export const ObjectDetailsLeftContainer = styled(Stack)(({ theme }) => ({
  maxHeight: "75vh",
  overflowY: "scroll",
  scrollBehavior: "smooth",

  ["::-webkit-scrollbar"]: {
    width: 0,
    background: "transparent"
  },

  [theme.breakpoints.down("md")]: {
    maxHeight: "none",
    overflowY: "hidden",
    marginBottom: "20px"
  }
}));

export const ObjectInfoRow = styled(Box)({
  display: "flex",
  flexDirection: "row",
  marginTop: "10px",
  gap: "25px"
});

export const RowText = styled(Box)({
  fontSize: "12px",
  fontWeight: 400,
  color: "#646D7B",
  marginLeft: "6px",
  marginTop: "2px"
});
