import { FC, useEffect, useState } from "react";
import { Box, Stack, useTheme } from "@mui/material";
import {
  DateRangePicker,
  FormikCheckbox,
  FormikSelectField,
  FormikSelectSearchField,
  TableToggleField
  // FormikToggleField, // TODO will be added later
} from "@vilocnv/allsetra-core";
import { filterObjectsByGroupAndType } from "app/data/helpers";
import DateAndTimeFilters from "../../DateAndTimeFilters/DateAndTimeFilters";
import { RIDE_MODES } from "app/data/constants";
import { useTranslation } from "react-i18next";
import TimeFilters from "../../TimeFilters/TimeFilters";
import DaysFilters from "../../DaysFilter/DaysFilter";
import { FormLabelText, LabelRowBox } from "./InnerForm.styled";
import { isEmpty } from "lodash";

interface Values {
  group: string[];
  object: string[];
  key: string[];
  objectType: string[];
  assignedToAllObjects: boolean;
}

interface Props {
  groups: Array<any>;
  objectTypes: Array<any>;
  objects: Array<any>;
  keys: Array<any>;
  dataLoading?: boolean;
  translator: any;
  fetchOnDebounce: (value: string) => void;
  onChange?: () => void;
  values: Values;
  setFieldValue: any;
  isCO2InnerForm?: boolean;
  isMultipleRidesForm?: boolean;
  isMileageReport?: boolean;
  handleDateChange: (value: any) => void;
  dateValues: any;
  dateRangeLoading: boolean;
  //   handleDateRangeSubmit?: () => void;
  reportType: number;
}

const InnerForm: FC<Props> = ({
  groups,
  objectTypes,
  objects,
  keys,
  dataLoading = false,
  translator,
  fetchOnDebounce,
  onChange,
  values,
  setFieldValue,
  isCO2InnerForm = false,
  isMultipleRidesForm = false,
  isMileageReport = false,
  handleDateChange,
  dateValues,
  dateRangeLoading,
  //   handleDateRangeSubmit
  reportType
}) => {
  const theme = useTheme();
  const { t } = useTranslation(["translation", "formFieldsTranslation"]);
  const [isSelectDaysAndTimeEnable, setIsSelectDaysAndTimeEnable] =
    useState(false);

  useEffect(() => {
    const filteredObjects = filterObjectsByGroupAndType(
      values,
      objects,
      groups
    );
    const newObjectsValues = filteredObjects
      .filter((item) => values.object.includes(item.uniqueId))
      .map((item) => item.uniqueId);
    setFieldValue("object", newObjectsValues);
  }, [values.group, values.objectType]);

  const handleToggleDaysAndTimeChange = (value: boolean) => {
    setIsSelectDaysAndTimeEnable(value);
  };

  return (
    <Stack spacing={2}>
      {/* {isMileageReport ? (
        <FormikSelectField
          theme={theme}
          label={translator("rideMode", { ns: "formFieldsTranslation" })}
          placeholder={translator("rideMode", { ns: "formFieldsTranslation" })}
          name="rideModes"
          options={RIDE_MODES}
          optionLabelKey="name"
          optionValueKey="value"
          multiple
          searchable
          fullWidth
        />
      ) : null} */}
      <FormLabelText>{t("reports.selectDateRange")}</FormLabelText>
      <Box
        sx={{
          marginTop: "2px !important"
          // "& > div": {
          //   marginTop: "2px !important"
          // }
        }}
      >
        <DateRangePicker
          name="datepicker"
          onChange={handleDateChange}
          value={dateValues}
          // onSubmit={handleDateRangeSubmit}
          disabled={dateRangeLoading}
          theme={theme}
          translator={t}
        />
      </Box>

      <FormikCheckbox
        theme={theme}
        name="assignedToAllObjects"
        label={translator("selectAllObjects.label", {
          ns: "formFieldsTranslation"
        })}
      />

      {!values.assignedToAllObjects && (
        <>
          <LabelRowBox>
            <FormLabelText>
              {translator("objects.label", { ns: "formFieldsTranslation" })}
            </FormLabelText>
            {/* <FormLabelText>
          {values?.object?.length > 0
            ? `${values?.object?.length} ${translator("object", { ns: "formFieldsTranslation" })}${
                values?.object?.length === 1
                  ? ""
                  : translator("s", {
                      ns: "formFieldsTranslation"
                    })
              }`
            : translator("allObjects", { ns: "formFieldsTranslation" })}
        </FormLabelText> */}
          </LabelRowBox>
          <FormikSelectSearchField
            theme={theme}
            fetchOnDebounce={fetchOnDebounce}
            // label={translator("objects.label", { ns: "formFieldsTranslation" })}
            placeholder={translator("objects.placeholder", {
              ns: "formFieldsTranslation"
            })}
            name="object"
            options={filterObjectsByGroupAndType(values, objects, groups)}
            optionLabelKey="name"
            optionValueKey="uniqueId"
            loading={dataLoading}
            multiple
            searchable
            onChange={onChange}
            required={isMultipleRidesForm}
            fullWidth
            sx={{
              marginTop: "0 !important",
              "& .MuiAutocomplete-hasClearIcon.css-1x6bjyf-MuiAutocomplete-root .MuiOutlinedInput-root":
                {
                  paddingRight: "9px"
                }
            }}
            //@ts-ignore
            showStates
            showDropdownIcon
          />
        </>
      )}

      {reportType !== 1 && (
        <>
          <LabelRowBox>
            <FormLabelText>
              {translator("objectType.label", { ns: "formFieldsTranslation" })}
            </FormLabelText>
            <FormLabelText>
              {values?.objectType?.length > 0
                ? `${values?.objectType?.length} ${translator("objectType.label", { ns: "formFieldsTranslation" })}`
                : //  : translator("allObjectTypes", { ns: "formFieldsTranslation" })}
                  ""}
            </FormLabelText>
          </LabelRowBox>
          <FormikSelectField
            theme={theme}
            // label={translator("objectType.label", { ns: "formFieldsTranslation" })}
            placeholder={translator("objectType.placeholder", {
              ns: "formFieldsTranslation"
            })}
            name="objectType"
            options={objectTypes ?? []}
            optionLabelKey="name"
            optionValueKey="uniqueId"
            loading={dataLoading}
            multiple
            searchable
            fullWidth
            sx={{
              marginTop: "0 !important",
              "& .MuiAutocomplete-hasClearIcon.css-1x6bjyf-MuiAutocomplete-root .MuiOutlinedInput-root":
                {
                  paddingRight: "9px"
                }
            }}
            //@ts-ignore
            showStates
            showDropdownIcon
          />
        </>
      )}

      {groups?.length ? (
        <>
          {/* <FormLabelText>
            {translator("groups.label", { ns: "formFieldsTranslation" })}
          </FormLabelText> */}
          <LabelRowBox>
            <FormLabelText>
              {translator("groups.label", { ns: "formFieldsTranslation" })}
            </FormLabelText>
            <FormLabelText>
              {values.group.length > 0
                ? `${values.group.length} ${
                    values.group.length === 1
                      ? translator("group", { ns: "formFieldsTranslation" })
                      : translator("groups.label", {
                          ns: "formFieldsTranslation"
                        })
                  }`
                : // : translator("allGroups", { ns: "formFieldsTranslation" })}
                  ""}
            </FormLabelText>
          </LabelRowBox>
          <FormikSelectField
            theme={theme}
            // label={translator("groups.label", { ns: "formFieldsTranslation" })}
            placeholder={translator("groups.placeholder", {
              ns: "formFieldsTranslation"
            })}
            name="group"
            options={groups ?? []}
            optionLabelKey="name"
            optionValueKey="uniqueId"
            loading={dataLoading}
            multiple
            searchable
            fullWidth
            sx={{
              marginTop: "0 !important",
              "& .MuiAutocomplete-hasClearIcon.css-1x6bjyf-MuiAutocomplete-root .MuiOutlinedInput-root":
                {
                  paddingRight: "9px"
                }
            }}
            //@ts-ignore
            showStates
            showDropdownIcon
          />
        </>
      ) : (
        <></>
      )}
      {/* <FormLabelText>
        {translator("keys.label", { ns: "formFieldsTranslation" })}
      </FormLabelText> */}
      {!isEmpty(keys) && reportType !== 1 && (
        <>
          <LabelRowBox>
            <FormLabelText>
              {translator("keys.label", { ns: "formFieldsTranslation" })}
            </FormLabelText>
            <FormLabelText>
              {values?.key.length > 0
                ? `${values?.key?.length} ${
                    values?.key?.length === 1
                      ? translator("key", { ns: "formFieldsTranslation" })
                      : translator("keys.label", {
                          ns: "formFieldsTranslation"
                        })
                  }`
                : // : translator("allKeys", { ns: "formFieldsTranslation" })}
                  ""}
            </FormLabelText>
          </LabelRowBox>
          <FormikSelectField
            theme={theme}
            // label={translator("keys.label", { ns: "formFieldsTranslation" })}
            placeholder={translator("keys.placeholder", {
              ns: "formFieldsTranslation"
            })}
            name="key"
            options={keys ?? []}
            optionLabelKey="label"
            optionValueKey="uniqueId"
            loading={dataLoading}
            multiple
            searchable
            fullWidth
            sx={{
              marginTop: "0 !important",
              "& .MuiAutocomplete-hasClearIcon.css-1x6bjyf-MuiAutocomplete-root .MuiOutlinedInput-root":
                {
                  paddingRight: "9px"
                }
            }}
            //@ts-ignore
            showStates
            showDropdownIcon
          />
        </>
      )}

      {/* <Days */}
      {reportType !== 1 && (
        <TableToggleField
          label={t("common.selectDaysAndTime") ?? "Select days and time"}
          name="selectDaysAndTime"
          onChange={(e) => handleToggleDaysAndTimeChange(e.target.checked)}
          checked={isSelectDaysAndTimeEnable}
        />
      )}

      {reportType !== 1 && isSelectDaysAndTimeEnable && (
        <>
          <DaysFilters values={values} />

          <TimeFilters values={values} />
        </>
      )}

      {/* TODO will be aded later  */}
      {/* {isCO2InnerForm && (
        <>
          <RideModeFilters />
          <FormikToggleField
            label={translator("excludeRides.label", {
              ns: "formFieldsTranslation",
            })}
            name="excludeRides"
          />
        </>
      )} */}
    </Stack>
  );
};

export default InnerForm;
