import { TableColumn, StatusBadge, types, utils } from "@vilocnv/allsetra-core";

export const ACCOUNT_KEYS_TABLE_COLUMNS = (
  translator: any
): TableColumn<types.IKey>[] => [
  {
    name: translator("tableHeading.label", {
      ns: "tableHeadingsTranslation"
    }),
    selector: (row: types.IKey) => row.label || "",
    sortable: true
  },
  {
    name: translator("tableHeading.keyID", {
      ns: "tableHeadingsTranslation"
    }),
    selector: (row: types.IKey) => row?.keyId || "",
    sortable: true
  },
  {
    name: translator("tableHeading.status", {
      ns: "tableHeadingsTranslation"
    }),
    cell: (row: types.IKey) => <StatusBadge isDeactivated={!row?.isActive} />,
    sortable: true
  },
  {
    name: translator("tableHeading.activationTimeStamp", {
      ns: "tableHeadingsTranslation"
    }),
    selector: (row: types.IKey) =>
      row.activationDate
        ? utils.formatDate({ date: row?.activationDate })
        : "N/A",
    sortable: true
  },
  {
    name: translator("tableHeading.deactivationTimeStamp", {
      ns: "tableHeadingsTranslation"
    }),
    selector: (row: types.IKey) =>
      row.deactivationDate
        ? utils.formatDate({ date: row?.deactivationDate })
        : "N/A",
    sortable: true
  },
  {
    name: translator("tableHeading.privateMileage", {
      ns: "tableHeadingsTranslation"
    }),
    selector: (row: types.IKey & { yearPrivateMileage: any }) =>
      row?.yearPrivateMileage !== undefined && row?.yearPrivateMileage !== null
        ? `${row?.yearPrivateMileage?.toString()} km`
        : "N/A",
    sortable: true
  }
];
