import { FC, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack, useTheme } from "@mui/material";
import { Topbar } from "@vilocnv/allsetra-core";
import { useTranslation } from "react-i18next";
import { ScheduledReportsIcon, ReportsHistoryIcon } from "assets/icons";
import { ManageButton, MenuItemBox } from "./ReportsTopbar.styled";
import { useAppSelector } from "hooks";
import { selectAccountFeaturesState } from "app/data/selectors";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { KeyboardArrowDown } from "@mui/icons-material";
import ScheduleReports from "../ScheduleReports/ScheduleReports";

interface Props {
  title: string;
  disabled?: boolean;
  hideScheduleReports?: boolean;
  hideReportHistory?: boolean;
}

const ReportsMainTopbar: FC<Props> = ({
  title,
  disabled = false,
  hideScheduleReports = false,
  hideReportHistory = false
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { t } = useTranslation();

  const { features } = useAppSelector(selectAccountFeaturesState);

  const handleScheduleReports = () => {
    navigate("/dashboard/reports/schedule-reports");
  };

  const handleReportsHistory = () => {
    navigate("/dashboard/reports/exported-history");
  };

  const handleScheduleAReport = () => {
    // navigate("/dashboard/reports/schedule-reports");
  };

  return (
    <Topbar
      theme={theme}
      title={title}
      //   dropdownTitle={dropdownTitle}
      //   dropdownItems={reportsDropdown}
      //   disabled={disabled}
      //   increasedDropdownWidth={true}
    >
      {features.isAutomaticReportsEnabled && (
        <Fragment>
          <Stack gap={4} direction="row">
            <ManageButton
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              endIcon={<KeyboardArrowDown />}
            >
              {t("reports.manage")}
            </ManageButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button"
              }}
              sx={{
                "& .MuiPaper-root": {
                  border: "1px solid #DFE5F4",
                  borderRadius: "6px",
                  marginTop: "4px"
                },
                "& .MuiList-root": {
                  py: 0
                }
              }}
            >
              <MenuItem
                id="link-schedule-reports"
                onClick={handleScheduleAReport}
                sx={{ py: 1.25, px: 2.5 }}
              >
                <ScheduleReports
                  isMainReportsPage
                  reportType={1}
                  reportExportTypeValue={1}
                />
              </MenuItem>
              {!hideScheduleReports && (
                <MenuItem
                  id="link-schedule-reports"
                  onClick={handleScheduleReports}
                  sx={{
                    py: 1.25,
                    px: 2.5,
                    borderBottom: "1px solid #DFE5F4",
                    borderTop: "1px solid #DFE5F4"
                  }}
                >
                  <MenuItemBox>
                    <ScheduledReportsIcon />
                    {t("reports.scheduledReports")}
                  </MenuItemBox>
                </MenuItem>
              )}
              {!hideReportHistory && (
                <MenuItem
                  id="link-reports-history"
                  onClick={handleReportsHistory}
                  sx={{ py: 1.25, px: 2.5 }}
                >
                  <MenuItemBox>
                    <ReportsHistoryIcon />
                    {t("reports.reportsHistory")}
                  </MenuItemBox>
                </MenuItem>
              )}
            </Menu>
          </Stack>
        </Fragment>
      )}
    </Topbar>
  );
};

export default ReportsMainTopbar;
