import { FC, memo, useMemo } from "react";
import { Table, TableColumn } from "@vilocnv/allsetra-core";
import { CO2_REPORT_INNER_TABLE_COLUMNS } from "app/data/constants";
import { useTranslation } from "react-i18next";

const customStyles = {
  cells: {
    style: {
      justifyContent: "center"
    }
  }
};

interface CO2ReportFinalDataProps {
  data: any;
  isShowKeyCol?: boolean;
}

const CO2ReportFinalData: FC<CO2ReportFinalDataProps> = ({
  data,
  isShowKeyCol
}) => {
  const { t } = useTranslation(["translation", "tableHeadingsTranslation"]);
  const tableColumns: TableColumn<any>[] = useMemo(
    () => CO2_REPORT_INNER_TABLE_COLUMNS(t, isShowKeyCol),
    []
  );

  return (
    <Table
      columns={tableColumns}
      data={data.data}
      noTableHead={true}
      pagination={false}
      customStyles={customStyles}
      translator={t}
    />
  );
};

export default memo(CO2ReportFinalData);
