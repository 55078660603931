import { Box, Button, styled } from "@mui/material";

export const TopbarLinkText = styled(Box)({
  cursor: "pointer",
  display: "flex",
  gap: "10px",
  alignItems: "center"
});

export const MenuItemBox = styled(Box)({
  display: "flex",
  gap: "12px",
  fontSize: "14px",
  color: "#212B33"
});

export const ManageButton = styled(Button)({
  padding: "10px 24px",
  border: "1px solid #00B2A3 ",
  borderRadius: "6px",
  textTransform: "none",
  width: "139px",
  height: "40px"
});
