import { FC } from "react";
import {
  ContentSectionLayout,
  TwoColsLayout,
  FormikSelectField,
  FormikInputField,
  type types
} from "@vilocnv/allsetra-core";
import { ChildFormBox } from "../ObjectSettingsForm.styled";
import { useTheme } from "@mui/material";
import { isEmpty } from "lodash";

interface Props {
  activeObject: types.IObject | null;
}

const DynamicFieldsSetting: FC<Props> = ({ activeObject }) => {
  const theme = useTheme();

  return activeObject?.metadata?.length ? (
    <ContentSectionLayout
      title="Dynamic fields"
      subTitle="Fill in the object dynamic fields.."
    >
      <ChildFormBox>
        <TwoColsLayout>
          {activeObject.metadata.map((item: any, index) => {
            const { field } = item;
            const fieldId = field.uniqueId;
            const fieldType = field.fieldType;

            switch (fieldType) {
              case 4:
                return (
                  <FormikSelectField
                    key={fieldId}
                    theme={theme}
                    label={item.field.label}
                    name={`metadata[${index}].value`}
                    options={item.field.optionsValues || []}
                    optionLabelKey="name"
                    optionValueKey="value"
                    fullWidth
                    disabled={
                      item.field.isReadOnly || isEmpty(item.field.optionsValues)
                    }
                    readOnly={item.field.isReadOnly}
                  />
                );

              default:
                return (
                  <FormikInputField
                    theme={theme}
                    label={item.field.label}
                    name={`metadata[${index}].value`}
                    placeholder={item.field.label}
                    fullWidth
                    disabled={item.field.isReadOnly}
                  />
                );
            }
          })}
        </TwoColsLayout>
      </ChildFormBox>
    </ContentSectionLayout>
  ) : (
    <></>
  );
};

export default DynamicFieldsSetting;
