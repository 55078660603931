import React, { FC, Fragment, useEffect, useMemo, useState } from "react";
import {
  ContentSectionLayout,
  TwoColsLayout,
  FormikInputField,
  FormikSelectField,
  utils,
  FormikSelectSearchField,
  ChangeGroupForm
} from "@vilocnv/allsetra-core";
import { ChildFormBox } from "../ObjectSettingsForm.styled";
import { Add, LockedIcon } from "assets/icons";

import { useAppDispatch, useAppSelector, useSelectSearch } from "hooks";
import {
  selectAccountGroups,
  selectAccountsState,
  selectDrawerSelectedAccountId,
  selectObjectTypesState,
  selectQueriedObjectsState,
  selectSpecificObjectState
} from "app/data/selectors";
import {
  getAllAccountGroupsThunk,
  getObjectTypesByAccountThunk,
  postAccountsMinimalSearch
} from "app/features";
import {
  createReadOnlyMap,
  flattenAccounts,
  isFormFieldReadOnly
} from "app/data/helpers";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import { MuiButton } from "pages/dashboard/Reports/Reports.styled";
import { useGroupForm } from "hooks/useGroupForm";

interface Props {
  activeObject: any | null;
}

const ObjectInformationSetting: FC<Props> = ({ activeObject }) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);

  const { objects } = useAppSelector(selectQueriedObjectsState);
  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);

  const { selectedAccounts, loading: accountsLoading } =
    useAppSelector(selectAccountsState);

  const flattenedAccounts = flattenAccounts(selectedAccounts);

  const { accountGroups, loading: groupsLoading } =
    useAppSelector(selectAccountGroups);

  const { objectTypes, loading: objectTypesLoading } = useAppSelector(
    selectObjectTypesState
  );

  const { specificObject } = useAppSelector(selectSpecificObjectState);

  const { t } = useTranslation(["translation", "formFieldsTranslation"]);

  const {
    addGroupModal,
    editValues,
    isSubmitting,
    selectedLocalIds,
    toggleAddGroupModal,
    handleObjectsSearch,
    handleOnChangeGroupForm,
    addGroupHandler
  } = useGroupForm({ drawerSelectedAccountId });

  const handleAccountsSearch = (value: string) => {
    dispatch(
      postAccountsMinimalSearch({
        itemsPerPage: 50,
        page: 1,
        where: [{ field: "name", value, type: 0 }]
      })
    );
  };

  const extractObjects = useMemo(() => {
    return selectedGroupId
      ? accountGroups.find(
          (item: { uniqueId: string }) => item.uniqueId === selectedGroupId
        )?.objects || []
      : [];
  }, [selectedGroupId, accountGroups]);

  const selectedLocalObjects = useSelectSearch(selectedLocalIds, objects);

  const formObjects = [...extractObjects, ...selectedLocalObjects];

  const readOnlyMap = React.useMemo(
    () => createReadOnlyMap(specificObject),
    [specificObject]
  );

  useEffect(() => {
    dispatch(postAccountsMinimalSearch(utils.getCommonParamsForApi()));
    dispatch(getAllAccountGroupsThunk(drawerSelectedAccountId || ""));
    dispatch(getObjectTypesByAccountThunk(drawerSelectedAccountId || ""));
  }, []);

  return (
    <ContentSectionLayout
      title={t("titles.objectInfo")}
      subTitle={t("descriptions.objectInfo")}
    >
      <ChangeGroupForm
        open={addGroupModal}
        onClose={toggleAddGroupModal}
        accountId={drawerSelectedAccountId}
        objects={formObjects}
        initialValues={editValues}
        theme={theme}
        onSubmit={addGroupHandler}
        submitting={isSubmitting}
        isCustomerPortal={true}
        translator={t}
        isGroups={true}
        handleDebounce={handleObjectsSearch}
        //@ts-ignore
        onChange={handleOnChangeGroupForm}
      />
      <ChildFormBox>
        <TwoColsLayout>
          <FormikInputField
            theme={theme}
            label={t("objectName.label", { ns: "formFieldsTranslation" })}
            placeholder={t("objectName.placeholder", {
              ns: "formFieldsTranslation"
            })}
            name="name"
            fullWidth
            disabled={isFormFieldReadOnly("objectName", readOnlyMap)}
          />
          <FormikSelectField
            theme={theme}
            label={t("objectType.label", { ns: "formFieldsTranslation" })}
            placeholder={t("objectType.placeholder", {
              ns: "formFieldsTranslation"
            })}
            name="objectTypeId"
            options={objectTypes ?? []}
            optionLabelKey={"name"}
            optionValueKey={"uniqueId"}
            loading={objectTypesLoading}
            fullWidth
            disabled={isFormFieldReadOnly("objectType", readOnlyMap)}
          />
        </TwoColsLayout>

        <TwoColsLayout hideDivider>
          <FormikSelectSearchField
            fetchOnDebounce={handleAccountsSearch}
            theme={theme}
            label={t("assignedAccounts.label", { ns: "formFieldsTranslation" })}
            placeholder={t("assignedAccounts.placeholder", {
              ns: "formFieldsTranslation"
            })}
            name="accounts"
            options={flattenedAccounts ?? []}
            optionLabelKey={"name"}
            optionValueKey={"uniqueId"}
            loading={accountsLoading}
            searchable
            multiple
            fullWidth
            disabled={isFormFieldReadOnly("assignedAccounts", readOnlyMap)}
          />
          <FormikSelectField
            theme={theme}
            label={t("groups.label", { ns: "formFieldsTranslation" })}
            placeholder={t("groups.placeholder", {
              ns: "formFieldsTranslation"
            })}
            name="groups"
            options={accountGroups ?? []}
            optionLabelKey={"name"}
            optionValueKey={"uniqueId"}
            loading={groupsLoading}
            searchable
            multiple
            fullWidth
            disabled={isFormFieldReadOnly("groups", readOnlyMap)}
          />
          <Fragment />
          <MuiButton
            id="create-new-group-button"
            aria-haspopup="true"
            onClick={toggleAddGroupModal}
            variant="text"
            sx={{ textTransform: "none", marginBottom: "24px" }}
          >
            {<Add width={16} />}
            {t("createNewGroup.label", { ns: "formFieldsTranslation" })}
          </MuiButton>
        </TwoColsLayout>

        <TwoColsLayout>
          <FormikInputField
            theme={theme}
            label={t("alarmOwner.label", { ns: "formFieldsTranslation" })}
            placeholder={t("alarmOwner.placeholder", {
              ns: "formFieldsTranslation"
            })}
            name="alarmOwner"
            fullWidth
            disabled
            endIcon={<LockedIcon />}
          />
          <FormikInputField
            theme={theme}
            label={t("invoiceOwner.label", { ns: "formFieldsTranslation" })}
            placeholder={t("invoiceOwner.placeholder", {
              ns: "formFieldsTranslation"
            })}
            name="invoiceOwner"
            fullWidth
            disabled
            endIcon={<LockedIcon />}
          />
        </TwoColsLayout>

        <TwoColsLayout hideDivider>
          <FormikInputField
            theme={theme}
            label={t("id.name", {
              ns: "formFieldsTranslation"
            })}
            name="aNumber"
            placeholder={t("id.placeholder", {
              ns: "formFieldsTranslation"
            })}
            fullWidth
            disabled
            endIcon={<LockedIcon />}
          />
          <FormikInputField
            theme={theme}
            label={t("multiviewerName.name", {
              ns: "formFieldsTranslation"
            })}
            name="multiviewerName"
            placeholder={t("multiviewerName.placeholder", {
              ns: "formFieldsTranslation"
            })}
            fullWidth
            disabled
            endIcon={<LockedIcon />}
          />
        </TwoColsLayout>
      </ChildFormBox>
    </ContentSectionLayout>
  );
};

export default ObjectInformationSetting;
