import { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, useFormikContext, FormikHelpers } from "formik";
import { Topbar, types, toast } from "@vilocnv/allsetra-core";
import { Box, useTheme } from "@mui/material";

// Inner Forms
import ObjectInformationSetting from "./ObjectInformationSetting";
// import ReminderSetting from "./ReminderSetting";
import NotificationsSetting from "./NotificationsSetting";
import ObjectWorkingHoursSetting from "./ObjectWorkingHoursSetting";
import DynamicFieldsSetting from "./DynamicFieldsSetting";
import AssignUserSetting from "./AssignUserSetting";

// Data
import { useAppDispatch, useAppSelector } from "hooks";
import {
  getSpecificObjectByIdThunk,
  patchObjectMilageCorrectionThunk
} from "app/features";
import { selectDrawerSelectedAccountId } from "app/data/selectors";
import { signalRGenerateSuccessToastMessage } from "app/data/helpers";
import { useTranslation } from "react-i18next";
import { SignalRService } from "app/data/services";

interface Props {
  activeObject: types.IObject | null;
  objectsFormSubmitting: boolean;
}

const InnerForm: FC<Props> = ({ activeObject, objectsFormSubmitting }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();

  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);

  const [formSubmitting, setFormSubmitting] = useState(false);

  const { t } = useTranslation(["translation", "formFieldsTranslation"]);

  const {
    handleSubmit,
    isSubmitting,
    dirty,
    isValid,
    resetForm,
    values: objectValues
  } = useFormikContext<any>();

  const milageCorrectionSubmitHandler = async (
    values: types.IObjectMilageCorrection,
    formikHelpers: FormikHelpers<types.IObjectMilageCorrection>
  ) => {
    formikHelpers.setSubmitting(true);
    setFormSubmitting(true);

    const { type } = await dispatch(
      patchObjectMilageCorrectionThunk({
        accountId: drawerSelectedAccountId ?? "",
        objectId: objectValues?.uniqueId,
        data: values
      })
    );

    if (type === "objects/patchObjectMilageCorrectionThunk/fulfilled") {
      SignalRService.hubConnection?.on("EventRaised", (event: any) => {
        if (
          event.eventName ===
          types.BackendEventsEnum.ObjectCorrectionReportedEvent
        ) {
          formikHelpers.setSubmitting(false);
          setFormSubmitting(false);
          toast.success(
            signalRGenerateSuccessToastMessage(
              event.eventName,
              "Mileage Correction",
              "updated"
            )
          );
          dispatch(
            getSpecificObjectByIdThunk({
              accountId: drawerSelectedAccountId ?? "",
              objectId: params.id ?? ""
            })
          );
        }
      });
    } else {
      formikHelpers.setSubmitting(false);
      setFormSubmitting(false);
    }
  };

  return (
    <Form>
      <Topbar
        theme={theme}
        breadcrumbTitle={t("drawerMenuLinks.objects")}
        breadcrumbRedirectTo={() => navigate(-1)}
        title={activeObject?.name || ""}
        primaryButton={{
          id: "object-settings-change",
          variant: "contained",
          text: t("buttonsText.saveChanges"),
          onClick: handleSubmit,
          loading: isSubmitting || objectsFormSubmitting,
          disabled: !dirty ? isValid : !isValid
        }}
        secondaryButton={{
          variant: "text",
          text: t("buttonsText.cancel"),
          onClick: () => {
            resetForm();
            navigate(-1);
          }
        }}
      />
      <Box mx={4}>
        <ObjectInformationSetting activeObject={activeObject} />
        <DynamicFieldsSetting activeObject={activeObject} />
        <ObjectWorkingHoursSetting activeObject={activeObject} />
        {/* <ObjectMilageCorrectionForm
          onSubmitHandler={milageCorrectionSubmitHandler}
          submitting={formSubmitting}
        /> */}
        <AssignUserSetting activeObject={activeObject} />
        {/* <ReminderSetting /> */}
        {/* <NotificationsSetting /> */}
      </Box>
    </Form>
  );
};

export default InnerForm;
