import { FC, useEffect, useState, useRef, useMemo } from "react";
import {
  AccordionDetails,
  Box,
  CircularProgress,
  useTheme
} from "@mui/material";
import { FormikHelpers } from "formik";
import ReportsFilterBar from "components/common/reports/ReportFiltersBar/ReportsFilterBar";
import ReportsTopbar from "components/common/reports/ReportsTopbar/ReportsTopbar";
import WorkingHoursFilterForm from "components/forms/Reports/WorkingHoursFilterForm";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import { GraphicalReportBarChart } from "@vilocnv/allsetra-core";
// Data
import { useAppDispatch, useAppSelector, useReportCommonStates } from "hooks";
import { selectAccountKeysState } from "app/data/selectors";
import {
  generateReportThunk,
  getAllKeysByAccountThunk,
  resetReports,
  setReportsFilterData
} from "app/features";
import {
  convertToNormalTime,
  formattedWorkingHours,
  reportTransformTimeForAPI
} from "app/data/helpers";
import { WORKING_HOURS_HEADERS } from "app/data/constants";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetailsBox,
  AccordionSummary,
  CenterTextBox,
  RidesTextBox,
  SummaryContentBox
} from "./Reports.styled";
import { restructureData } from "app/data/helpers/ReportHelpers/graphicalReportHelpers";
import moment from "moment";

const DEFAULT_FILTER_VALUES = {
  key: [],
  group: [],
  object: [],
  objectType: []
};
const GraphicalRides: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const {
    drawerSelectedAccountId,
    objectTypes,
    objectTypesLoading,
    objectsLoading,
    accountGroups,
    report,
    reportsLoading,
    filterOpen,
    setFilterOpen,
    dateFormat,
    setDateFormat,
    dateValues,
    setDateValues,
    localReport,
    setLocalReport,
    handleObjectsSearch,
    selectedLocalObjects,
    handleOnChangeReportFilterForm,
    preloadedObjects
  } = useReportCommonStates();
  const [filterValues, setFilterValues] = useState<any>(DEFAULT_FILTER_VALUES);
  const [openModal, setOpenModal] = useState(false);

  const parentRef = useRef(null);
  const [parentWidth, setParentWidth] = useState(0);
  const { t } = useTranslation(["translation", "tableHeadingsTranslation"]);
  const { accountKeys, loading: accountKeysLoading } = useAppSelector(
    selectAccountKeysState
  );

  const [apiPayload, setApiPayload] = useState(null);

  // useEffect(() => {
  //   dispatch(resetReports());
  // }, []);

  // const formattedData = formattedWorkingHours(localReport, dateFormat);

  const formattedData = useMemo(() => {
    const data = formattedWorkingHours(localReport, dateFormat);

    return data;
  }, [localReport, dateFormat]);

  useEffect(() => {
    if (drawerSelectedAccountId !== null) {
      dispatch(getAllKeysByAccountThunk(drawerSelectedAccountId));
    }
  }, [drawerSelectedAccountId]);

  const workingHoursFiltersSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true);
    const startDate = new Date(dateValues.startDate);
    const endDate = new Date(dateValues.endDate);
    const sameDate = startDate.toDateString() === endDate.toDateString();
    if (sameDate) {
      startDate.setHours(0, 0, 0);
      endDate.setHours(23, 59, 59);
    }
    const formattedStartDate = convertToNormalTime(startDate);
    const formattedEndDate = convertToNormalTime(endDate, true);
    setFilterValues(values);

    const payload = {
      ...(apiPayload ?? {}),
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      ...values,
      reportType: 3,
      daysOfWeek:
        values.daysOfWeek.length === 7 ? [] : values.daysOfWeek.map(Number),
      startTime: reportTransformTimeForAPI(values.startTime + ":00", startDate),
      endTime: reportTransformTimeForAPI(values.endTime + ":00", endDate)
    };

    dispatch(setReportsFilterData(payload));
    setApiPayload(payload);

    formikHelpers.setSubmitting(false);

    setFilterOpen(false);
  };

  const handleDateChange = (value: any) => {
    setDateValues(value);
  };
  const handleDateRangeSubmit = () => {
    const startDate = convertToNormalTime(dateValues.startDate);
    const endDate = convertToNormalTime(dateValues.endDate, true);

    let updatedFilterValues = { ...filterValues };
    if (filterValues.daysOfWeek) {
      updatedFilterValues = {
        ...filterValues,
        daysOfWeek:
          filterValues.daysOfWeek.length === 7
            ? []
            : filterValues.daysOfWeek.map(Number),
        startTime: reportTransformTimeForAPI(
          filterValues.startTime + ":00",
          dateValues.startDate
        ),
        endTime: reportTransformTimeForAPI(
          filterValues.endTime + ":00",
          dateValues.endDate
        )
      };
    }

    const payload = {
      startDate,
      endDate,
      ...updatedFilterValues,
      reportType: 3
    };

    setApiPayload(payload);
  };
  const handleLoadReport = () => {
    dispatch(setReportsFilterData(apiPayload));
    dispatch(generateReportThunk(apiPayload));
  };

  const formObjects = [...selectedLocalObjects];

  //@ts-ignore
  const restructuredData = useMemo(() => {
    const data = restructureData(formattedData);
    return data;
  }, [formattedData, localReport]);

  useEffect(() => {
    const updateParentWidth = () => {
      if (parentRef.current) {
        //@ts-ignore
        const width = parentRef.current.clientWidth;
        setParentWidth(width);
      }
    };
    updateParentWidth();
    window.addEventListener("resize", updateParentWidth);
    handleDateRangeSubmit();
    return () => {
      window.removeEventListener("resize", updateParentWidth);
    };
  }, [report, formattedData]);

  const [expandedFirstLevel, setExpandedFirstLevel] = useState<string[]>([]);
  const [expandedSecondLevel, setExpandedSecondLevel] = useState<string[]>([]);

  const handleFirstLevelAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedFirstLevel((prev) =>
        isExpanded ? [...prev, panel] : prev.filter((p) => p !== panel)
      );
    };

  const handleSecondLevelAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedSecondLevel((prev) =>
        isExpanded ? [...prev, panel] : prev.filter((p) => p !== panel)
      );
    };

  useEffect(() => {
    setLocalReport(report);
  }, [report]);

  return (
    <Box>
      <ReportsTopbar
        dropdownTitle={t("reports.graphicalRides")}
        disabled={reportsLoading ? true : false}
      />
      <Box mx={4}>
        <ReportsFilterBar
          setFilterOpen={setFilterOpen}
          dateFormat={dateFormat}
          setDateFormat={setDateFormat}
          handleDateChange={handleDateChange}
          dateValues={dateValues}
          handleDateRangeSubmit={handleDateRangeSubmit}
          dateRangeLoading={reportsLoading}
          exportHeaders={WORKING_HOURS_HEADERS}
          formattedData={formattedData}
          hideExportButton={true}
          disableExportButton={restructuredData?.length > 0 ? false : true}
          reportType={2}
          reportExportTypeValue={3}
          openModal={openModal}
          setOpenModal={setOpenModal}
          exportModalButton={true}
          handleLoadReport={handleLoadReport}
        />
        <WorkingHoursFilterForm
          fetchOnDebounce={handleObjectsSearch}
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
          onSubmit={workingHoursFiltersSubmitHandler}
          groups={accountGroups}
          objectTypes={objectTypes}
          objects={formObjects.length ? formObjects : preloadedObjects}
          keys={accountKeys}
          dataLoading={
            objectsLoading || objectTypesLoading || accountKeysLoading
          }
          theme={theme}
          //@ts-ignore
          onChange={handleOnChangeReportFilterForm}
        />
        {reportsLoading ? (
          <CenterTextBox>
            <CircularProgress />
          </CenterTextBox>
        ) : restructuredData?.length ? (
          restructuredData?.map(
            //@ts-ignore
            (
              item: {
                serialNumber: string;
                dataByDate: [];
              },
              index
            ) => {
              return (
                <Accordion
                  disableGutters
                  key={`${item.serialNumber}-${index}`}
                  elevation={0}
                  expanded={expandedFirstLevel.includes(`panel${index}`)}
                  onChange={handleFirstLevelAccordionChange(`panel${index}`)}
                  // expanded={true}
                >
                  <AccordionSummary
                    expandIcon={
                      expandedFirstLevel.includes(`panel${index}`) ? (
                        <ExpandLessIcon />
                      ) : (
                        <ChevronRightIcon />
                      )
                    }
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <SummaryContentBox>
                      <Box>{item.serialNumber}</Box>
                    </SummaryContentBox>
                  </AccordionSummary>
                  <AccordionDetails>
                    {item.dataByDate.map(
                      (
                        chartItem: {
                          date: string;
                          rides: number;
                          hourDurations: [];
                        },
                        chartIndex
                      ) => {
                        return (
                          <Accordion
                            disableGutters
                            key={`${chartItem.date}-${chartIndex}`}
                            elevation={0}
                            expanded={expandedSecondLevel.includes(
                              `subpanel${index}-${chartIndex}`
                            )}
                            onChange={handleSecondLevelAccordionChange(
                              `subpanel${index}-${chartIndex}`
                            )}
                          >
                            <AccordionSummary
                              expandIcon={
                                expandedSecondLevel.includes(
                                  `subpanel${index}-${chartIndex}`
                                ) ? (
                                  <ExpandLessIcon />
                                ) : (
                                  <ChevronRightIcon />
                                )
                              }
                              aria-controls="panel1-content"
                              id="panel1-header"
                            >
                              <SummaryContentBox>
                                <Box>
                                  {moment(chartItem.date).format(
                                    "ddd, MMMM D, YYYY"
                                  )}
                                </Box>
                                {/* <Box>{chartItem.date}</Box> */}
                                <RidesTextBox>
                                  {chartItem.rides}{" "}
                                  {chartItem.rides > 1
                                    ? t("reports.rides")
                                    : t("reports.ride")}
                                </RidesTextBox>
                              </SummaryContentBox>
                            </AccordionSummary>
                            <AccordionDetails>
                              <AccordionDetailsBox ref={parentRef}>
                                {/* //@ts_ignore */}
                                <Box id={`g-report-${index}-${chartIndex}`}>
                                  <GraphicalReportBarChart
                                    width={parentWidth}
                                    height={200}
                                    data={chartItem as any}
                                    barSize={20}
                                    cellFillColor={"rgba(132, 90, 252, 1)"}
                                    xAxisLabel={t("reports.hourOfTheDay")}
                                    yAxisLabel={t("reports.rideHours")}
                                    dataKey={"duration"}
                                  />
                                </Box>
                              </AccordionDetailsBox>
                            </AccordionDetails>
                          </Accordion>
                        );
                      }
                    )}
                  </AccordionDetails>
                </Accordion>
              );
            }
          )
        ) : (
          <CenterTextBox>There are no records to display</CenterTextBox>
        )}
      </Box>
    </Box>
  );
};
export default GraphicalRides;
