import { europeanCountryCodes } from "./activationPortalHelpers";

export const numberRegex = /^[0-9]+$/;
export const houseNumberRegex = /^[1-9][0-9]*$/;

export const isNumber = ({ value }: { value: string }) => {
  return !!value && numberRegex.test(value);
};

//Regex
export const phoneRegExp =
  /^[+]?((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const emailRegExp =
  // eslint-disable-next-line no-useless-escape
  /^(?!.*\.nl\.nl$)(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const passowrdRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export const nameRegex = /^[A-Za-z-\s]+$/;
export const lastNameRegex = /^[A-Za-z-\s]+$/;
export const postCodeRegex = /^[0-9]{4}[A-Z]{2}$/i;
export const otherPostCodeRegex = /^[A-Za-z0-9]{4,}$/;
export const alphanumericRegex = /^[A-Za-z0-9-]+$/;
export const streetRegex = /^[A-Za-z0-9-\s]+$/;
export const safetyQsAnsRegex = /^[A-Za-z0-9-\s]+$/;
export const cityRegex = /^[A-Za-z-\s]+$/;

export function isObject(value: any) {
  return typeof value === "object" && value !== null && !Array.isArray(value);
}

export const getElementSize = (
  element: HTMLElement | undefined
): { width: number; height: number } => {
  if (!element) return { width: 0, height: 0 };

  return { width: element.offsetWidth, height: element.offsetHeight };
};

type CountryCode = {
  value: string;
};

interface PhoneNumber {
  countryCode: string | null;
  phone: string;
}

function separateCountryCode(phoneNumber: string): PhoneNumber {
  const cleanedNumber = phoneNumber?.replace(/^\+/, "");

  for (let i = 1; i <= 3; i++) {
    const potentialCodeWithPlus = "+" + cleanedNumber.slice(0, i);
    if (
      europeanCountryCodes.some(
        (codeObj) => codeObj.value === potentialCodeWithPlus
      )
    ) {
      return {
        countryCode: potentialCodeWithPlus,
        phone: cleanedNumber.slice(i)
      };
    }
  }

  return {
    countryCode: "",
    phone: cleanedNumber
  };
}

const vatPatterns: Record<string, RegExp> = {
  AT: /^(AT)U\d{8}$/i, // Austria
  BE: /^(BE)\d{10}$/i, // Belgium
  BG: /^(BG)\d{9,10}$/i, // Bulgaria
  CY: /^(CY)[0-59]\d{7}[A-Z]$/i, // Cyprus
  CZ: /^(CZ)\d{8,10}$/i, // Czech Republic
  DE: /^(DE)[1-9]\d{8}$/i, // Germany
  DK: /^(DK)\d{8}$/i, // Denmark
  EE: /^(EE)10\d{7}$/i, // Estonia
  EL: /^(EL)\d{9}$/i, // Greece
  ES: /^(ES)[0-9A-Z]\d{7}[0-9A-Z]$/i, // Spain
  EU: /^(EU)\d{9}$/i, // EU-type
  FI: /^(FI)\d{8}$/i, // Finland
  FR: /^(FR)[0-9A-Z]{2}\d{9}$/i, // France
  GB: /^(GB)(\d{9}|\d{12}|GD\d{3}|HA\d{3})$/i, // UK
  GR: /^(GR)\d{8,9}$/i, // Greece
  HR: /^(HR)\d{11}$/i, // Croatia
  HU: /^(HU)\d{8}$/i, // Hungary
  IE: /^(IE)[0-9A-Z*+]{7}[A-Z]{1,2}$/i, // Ireland
  IT: /^(IT)\d{11}$/i, // Italy
  LV: /^(LV)\d{11}$/i, // Latvia
  LT: /^(LT)(\d{9}|\d{12})$/i, // Lithuania
  LU: /^(LU)\d{8}$/i, // Luxembourg
  MT: /^(MT)[1-9]\d{7}$/i, // Malta
  NL: /^(NL)\d{9}B\d{2}$/i, // Netherlands
  NO: /^(NO)\d{9}MVA$/i, // Norway (not EU)
  PL: /^(PL)\d{10}$/i, // Poland
  PT: /^(PT)\d{9}$/i, // Portugal
  RO: /^(RO)[1-9]\d{1,9}$/i, // Romania
  RU: /^(RU)(\d{10}|\d{12})$/i, // Russia
  RS: /^(RS)\d{9}$/i, // Serbia
  SI: /^(SI)[1-9]\d{7}$/i, // Slovenia
  SK: /^(SK)([1-9]\d([2-4]|[6-9])\d{7})$/i, // Slovakia
  SE: /^(SE)\d{10}01$/i // Sweden
};

export function validateVAT(vat: string) {
  const countryCode = vat.slice(0, 2).toUpperCase();
  if (!vatPatterns[countryCode]) return false;
  return vatPatterns[countryCode].test(vat);
}
