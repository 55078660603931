import { FC, useEffect, useState, useMemo } from "react";
import { Box, useTheme } from "@mui/material";
import { FormikHelpers } from "formik";
import { Table } from "@vilocnv/allsetra-core";
import ReportsFilterBar from "components/common/reports/ReportFiltersBar/ReportsFilterBar";
import ReportsTopbar from "components/common/reports/ReportsTopbar/ReportsTopbar";
import WorkingHoursFilterForm from "components/forms/Reports/WorkingHoursFilterForm";
import WorkingHoursDateRowCard from "components/cards/ReportCards/WorkingHoursDateRowCard/WorkingHoursDateRowCard";

// Data
import { useAppDispatch, useAppSelector, useReportCommonStates } from "hooks";
import { selectAccountKeysState } from "app/data/selectors";
import {
  generateReportThunk,
  getAllKeysByAccountThunk,
  resetReports,
  setReportsFilterData
} from "app/features";
import {
  convertToNormalTime,
  formattedWorkingHours,
  reportTransformTimeForAPI
} from "app/data/helpers";
import { WORKING_HOURS_FIRST_LAYER_COLUMNS } from "app/data/constants";
import { useTranslation } from "react-i18next";

const DEFAULT_FILTER_VALUES = {
  key: [],
  group: [],
  object: [],
  objectType: []
};

const WorkingHours: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const {
    drawerSelectedAccountId,
    objectTypes,
    objectTypesLoading,
    objectsLoading,
    accountGroups,
    report,
    reportsLoading,
    filterOpen,
    setFilterOpen,
    dateFormat,
    setDateFormat,
    dateValues,
    setDateValues,
    localReport,
    setLocalReport,
    handleObjectsSearch,
    selectedLocalObjects,
    handleOnChangeReportFilterForm,
    preloadedObjects
  } = useReportCommonStates();

  const [filterValues, setFilterValues] = useState<any>(DEFAULT_FILTER_VALUES);
  const [openModal, setOpenModal] = useState(false);

  const { t } = useTranslation(["translation", "tableHeadingsTranslation"]);
  const [apiPayload, setApiPayload] = useState(null);

  const tableColumns = useMemo(() => WORKING_HOURS_FIRST_LAYER_COLUMNS(t), [t]);

  const { accountKeys, loading: accountKeysLoading } = useAppSelector(
    selectAccountKeysState
  );

  // useEffect(() => {
  //   dispatch(resetReports());
  //   handleDateRangeSubmit();
  // }, []);

  useEffect(() => {
    setLocalReport(report);
  }, [report]);

  const formattedData = formattedWorkingHours(localReport, dateFormat);

  useEffect(() => {
    if (drawerSelectedAccountId !== null) {
      dispatch(getAllKeysByAccountThunk(drawerSelectedAccountId));
    }
  }, [drawerSelectedAccountId]);

  const workingHoursFiltersSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true);

    const startDate = new Date(dateValues.startDate);
    const endDate = new Date(dateValues.endDate);

    const sameDate = startDate.toDateString() === endDate.toDateString();

    if (sameDate) {
      startDate.setHours(0, 0, 0);
      endDate.setHours(23, 59, 59);
    }

    const formattedStartDate = convertToNormalTime(startDate);
    const formattedEndDate = convertToNormalTime(endDate, true);
    setFilterValues(values);

    const payload = {
      ...(apiPayload ?? {}),
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      ...values,
      reportType: 3,
      daysOfWeek:
        values.daysOfWeek.length === 7 ? [] : values.daysOfWeek.map(Number),
      startTime: reportTransformTimeForAPI(values.startTime + ":00", startDate),
      endTime: reportTransformTimeForAPI(values.endTime + ":00", endDate)
    };

    dispatch(setReportsFilterData(payload));
    setApiPayload(payload);

    formikHelpers.setSubmitting(false);
    setFilterOpen(false);
  };

  const handleDateChange = (value: any) => {
    setDateValues(value);
  };

  const handleDateRangeSubmit = () => {
    const startDate = convertToNormalTime(dateValues.startDate);
    const endDate = convertToNormalTime(dateValues.endDate, true);

    let updatedFilterValues = { ...filterValues };
    if (filterValues.daysOfWeek) {
      updatedFilterValues = {
        ...filterValues,
        daysOfWeek:
          filterValues.daysOfWeek.length === 7
            ? []
            : filterValues.daysOfWeek.map(Number),
        startTime: reportTransformTimeForAPI(
          filterValues.startTime + ":00",
          dateValues.startDate
        ),
        endTime: reportTransformTimeForAPI(
          filterValues.endTime + ":00",
          dateValues.endDate
        )
      };
    }

    const payload = {
      ...(apiPayload ?? {}),
      startDate,
      endDate,
      ...updatedFilterValues,
      reportType: 3
    };

    setApiPayload(payload);
  };

  const handleLoadReport = () => {
    dispatch(setReportsFilterData(apiPayload));
    dispatch(generateReportThunk(apiPayload));
  };

  const customStyles = {
    head: {
      style: {
        fontWeight: "900"
      }
    }
  };

  const formObjects = [...selectedLocalObjects];

  return (
    <Box>
      <ReportsTopbar
        dropdownTitle={t("reports.workingHours")}
        disabled={reportsLoading ? true : false}
      />
      <Box mx={4}>
        <ReportsFilterBar
          setFilterOpen={setFilterOpen}
          dateFormat={dateFormat}
          setDateFormat={setDateFormat}
          handleDateChange={handleDateChange}
          dateValues={dateValues}
          handleDateRangeSubmit={handleDateRangeSubmit}
          dateRangeLoading={reportsLoading}
          disableExportButton={!formattedData?.length}
          reportType={3}
          reportExportTypeValue={2}
          openModal={openModal}
          setOpenModal={setOpenModal}
          exportModalButton={true}
          hideExportButton={true}
          handleLoadReport={handleLoadReport}
        />
        <WorkingHoursFilterForm
          fetchOnDebounce={handleObjectsSearch}
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
          onSubmit={workingHoursFiltersSubmitHandler}
          groups={accountGroups}
          objectTypes={objectTypes}
          objects={formObjects.length ? formObjects : preloadedObjects}
          keys={accountKeys}
          dataLoading={
            objectsLoading || objectTypesLoading || accountKeysLoading
          }
          theme={theme}
          //@ts-ignore
          onChange={handleOnChangeReportFilterForm}
        />
        <Table
          columns={tableColumns}
          data={formattedData}
          progressPending={reportsLoading}
          expandableRows
          expandOnRowClicked
          expandableRowsComponent={WorkingHoursDateRowCard}
          customStyles={customStyles}
          pagination={false}
          translator={t}
        />
      </Box>
    </Box>
  );
};

export default WorkingHours;
