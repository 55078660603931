import { Table } from "@vilocnv/allsetra-core";
import { FC, memo } from "react";
import MilageRegFinalData from "../MilageRegFinalData/MilageRegFinalData";
import { MILAGE_REGISTRATION_DATE_RENDERING } from "app/data/constants";
import { useTranslation } from "react-i18next";

interface MilageRegExpendableRowCardProps {
  data: any;
  isShowKeyCol?: boolean;
}

const MilageRegExpendableRowCard: FC<MilageRegExpendableRowCardProps> = ({
  data,
  isShowKeyCol
}) => {
  const { t } = useTranslation(["translation", "tableHeadingsTranslation"]);

  return (
    <Table
      columns={MILAGE_REGISTRATION_DATE_RENDERING}
      data={data.records}
      expandableRows
      expandOnRowClicked
      expandableRowsComponent={MilageRegFinalData}
      expandableRowsComponentProps={{ isShowKeyCol: isShowKeyCol }}
      pagination={false}
      noTableHead={true}
      translator={t}
    />
  );
};

export default memo(MilageRegExpendableRowCard);
