import { FC, useMemo, useState } from "react";
import { toast, types } from "@vilocnv/allsetra-core";
import { Formik, FormikHelpers } from "formik";
import { isEmpty } from "lodash";

// CHILDREN
import InnerForm from "./children/InnerForm";

// DATA
import { useAppDispatch, useAppSelector } from "hooks";
import {
  objectDetailsFormatterForSettingsForm,
  objectDetailsValidationSchema,
  objectsFormatterForApi,
  signalRGenerateSuccessToastMessage
} from "app/data/helpers";
import {
  getSpecificObjectByIdThunk,
  postUpdateObjectThunk
} from "app/features";
import { selectDrawerSelectedAccountId } from "app/data/selectors";
import { useParams } from "react-router-dom";
import { SignalRService } from "app/data/services";

interface Props {
  activeObject: types.IObject | null;
}

const ObjectSettingsForm: FC<Props> = ({ activeObject }) => {
  const dispatch = useAppDispatch();
  const params = useParams();

  const [objectsFormSubmitting, setObjectsFormSubmitting] = useState(false);

  const drawerSelectedAccountId =
    useAppSelector(selectDrawerSelectedAccountId) || "";

  const initialValues = useMemo(
    () =>
      !isEmpty(activeObject)
        ? objectDetailsFormatterForSettingsForm(activeObject)
        : {},
    [activeObject]
  );

  const onFulfilledRequest = (
    objectName: string,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(false);
    setObjectsFormSubmitting(false);

    toast.success(
      signalRGenerateSuccessToastMessage(
        objectName,
        "Object Settings",
        "updated"
      )
    );

    dispatch(
      getSpecificObjectByIdThunk({
        accountId: drawerSelectedAccountId,
        objectId: params.id ?? ""
      })
    );
  };

  const saveChangesHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true);
    setObjectsFormSubmitting(true);

    const { type } = await dispatch(
      postUpdateObjectThunk({
        accountId: drawerSelectedAccountId,
        data: {
          ...values,
          users: values.users.map((userId: string) => ({
            accountId: drawerSelectedAccountId,
            userId: userId
          })),
          metadata: objectsFormatterForApi(values?.metadata || [])
        }
      })
    );

    if (type === "objects/postUpdateObjectThunk/fulfilled") {
      // Set a timeout to stop listening after 10 seconds
      const timeoutId = setTimeout(() => {
        SignalRService.hubConnection?.off("EventRaised");
        onFulfilledRequest(values.name, formikHelpers);
        console.log("Stopped listening for EventRaised after 10 seconds.");
      }, 10000);

      const handleEventRaised = (event: any) => {
        if (event.eventName === types.BackendEventsEnum.ObjectUpdatedEvent) {
          onFulfilledRequest(values.name, formikHelpers);

          // Clear the timeout if the event is received
          clearTimeout(timeoutId);

          // Remove the listener if it's a one-time use
          SignalRService.hubConnection?.off("EventRaised", handleEventRaised);
        }
      };

      SignalRService.hubConnection?.on("EventRaised", handleEventRaised);
    } else {
      formikHelpers.setSubmitting(false);
      setObjectsFormSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={objectDetailsValidationSchema}
      onSubmit={saveChangesHandler}
      enableReinitialize
      validateOnMount
    >
      <InnerForm
        activeObject={activeObject}
        objectsFormSubmitting={objectsFormSubmitting}
      />
    </Formik>
  );
};

export default ObjectSettingsForm;
