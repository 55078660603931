import { FC, useEffect, useMemo } from "react";
import { Theme } from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import { Modal, ModalProps, types } from "@vilocnv/allsetra-core";
import {
  scheduleReportsFormInitialValues,
  scheduleReportsFormValidationSchema,
  transformScheduleDataForForm
} from "app/data/helpers";
import InnerForm from "./children/InnerForm";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "hooks";
import { selectScheduleReportsState } from "app/data/selectors";

interface ReportFrequency {
  id: number;
  name: string;
}
interface Object {
  uniqueId: number;
  name: string;
}

interface ReportExportTypes {
  id: number;
  name: string;
}

export type ScheduleReportsFormProps = Pick<ModalProps, "open" | "onClose"> & {
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void;
  theme?: Theme;
  dataLoading?: boolean;
  objectTypes: Array<types.IObjectType>;
  objects: Array<Object>;
  keys: Array<types.IKey>;
  submitting?: boolean;
  fetchOnDebounce: (value: string) => void;
  onChange?: () => void;
  users?: Array<any>;
  usersLoading?: boolean;
  reportFrequency?: Array<ReportFrequency>;
  reportExportTypes?: Array<ReportExportTypes>;
  reportType?: number;
  reportExportTypeValue?: number;
};

const ScheduleReportsForm: FC<ScheduleReportsFormProps> = ({
  open,
  onClose,
  onSubmit,
  theme,
  dataLoading = false,
  objects,
  objectTypes,
  keys,
  submitting = false,
  fetchOnDebounce,
  onChange,
  users,
  usersLoading,
  reportFrequency,
  reportExportTypes,
  reportType,
  reportExportTypeValue
}) => {
  const { specificSchedule } = useAppSelector(selectScheduleReportsState);

  const { t } = useTranslation(["translation", "formFieldsTranslation"]);

  const isInEdit = specificSchedule ? true : false;

  const initialFormData = useMemo(() => {
    return isInEdit
      ? transformScheduleDataForForm(specificSchedule)
      : scheduleReportsFormInitialValues;
  }, [specificSchedule]);

  return (
    <Formik
      initialValues={initialFormData}
      validationSchema={scheduleReportsFormValidationSchema(t)}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnMount
    >
      {({ handleSubmit, isSubmitting, isValid, resetForm, values }) => (
        <Modal
          open={open}
          onClose={() => {
            onClose();
            resetForm();
          }}
          title={t("reports.scheduleReport")}
          primaryBtnProps={{
            type: "submit",
            text: t("buttonsText.save"),
            loading: isSubmitting || submitting,
            // @ts-ignore
            onClick: handleSubmit,
            disabled: !isValid
          }}
          secondaryBtnProps={{
            text: t("buttonsText.cancel"),
            onClick: () => {
              onClose();
              resetForm();
            }
          }}
          theme={theme}
          size="customSize"
        >
          <InnerForm
            objectTypes={objectTypes}
            objects={objects}
            keys={keys}
            dataLoading={dataLoading}
            translator={t}
            fetchOnDebounce={fetchOnDebounce}
            onChange={onChange}
            users={users}
            usersLoading={usersLoading}
            reportFrequency={reportFrequency}
            values={values}
            reportExportTypes={reportExportTypes}
            reportType={reportType}
            reportExportTypeValue={reportExportTypeValue}
          />
        </Modal>
      )}
    </Formik>
  );
};

export default ScheduleReportsForm;
