import { useState } from "react";
import { FormikHelpers } from "formik";
import { toast, types } from "@vilocnv/allsetra-core";
import { useAppDispatch } from "hooks";
import { SignalRService } from "app/data/services";
import {
  createOrUpdateAccountGroupsThunk,
  getAllAccountGroupsThunk,
  getObjectsByQueryThunk
} from "app/features";
import { signalRGenerateSuccessToastMessage } from "app/data/helpers";

interface UseGroupFormProps {
  drawerSelectedAccountId: string | null;
}

export const useGroupForm = ({
  drawerSelectedAccountId
}: UseGroupFormProps) => {
  const dispatch = useAppDispatch();

  const [addGroupModal, setAddGroupModal] = useState(false);
  const [editValues, setEditValues] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedLocalIds, setSelectedLocalIds] = useState<any[]>([]);
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);

  const toggleAddGroupModal = () => {
    setAddGroupModal(!addGroupModal);
    setEditValues({});
  };

  const handleObjectsSearch = (value: string) => {
    dispatch(
      getObjectsByQueryThunk({
        accountId: drawerSelectedAccountId || "",
        params: {
          itemsPerPage: 50,
          page: 1,
          where: [{ field: "name", value, type: 0 }]
        }
      })
    );
  };

  const handleOnChangeGroupForm = (val: any) => {
    setSelectedLocalIds(val);
  };

  const onCreateOrEditFulfilled = async (
    values: types.IChangeGroup,
    formikHelpers: FormikHelpers<types.IChangeGroup>
  ) => {
    toggleAddGroupModal();
    formikHelpers.resetForm();
    formikHelpers.setSubmitting(false);
    setIsSubmitting(false);

    toast.success(
      signalRGenerateSuccessToastMessage(
        values.name,
        "Group",
        values.uniqueId ? "updated" : "created"
      )
    );

    await dispatch(getAllAccountGroupsThunk(drawerSelectedAccountId ?? ""));
  };

  const addGroupHandler = async (
    values: types.IChangeGroup,
    formikHelpers: FormikHelpers<types.IChangeGroup>
  ) => {
    formikHelpers.setSubmitting(true);
    setIsSubmitting(true);

    const { type } = await dispatch(
      createOrUpdateAccountGroupsThunk({
        accountId: drawerSelectedAccountId,
        values: { ...values },
        groupId: values.uniqueId
      })
    );

    if (type === "accounts/createOrUpdateAccountGroupsThunk/fulfilled") {
      const timeoutId = setTimeout(() => {
        SignalRService.hubConnection?.off("EventRaised");
        onCreateOrEditFulfilled(values, formikHelpers);
        console.log("Stopped listening for EventRaised after 10 seconds.");
      }, 10000);

      const handleEventRaised = (event: any) => {
        if (
          event.eventName ===
            types.BackendEventsEnum.AccountGroupUpdatedEvent ||
          event.eventName ===
            types.BackendEventsEnum.GroupAssignedToAccountEvent
        ) {
          onCreateOrEditFulfilled(values, formikHelpers);
          clearTimeout(timeoutId);
          SignalRService.hubConnection?.off("EventRaised", handleEventRaised);
        }
      };

      SignalRService.hubConnection?.on("EventRaised", handleEventRaised);
    } else {
      formikHelpers.setSubmitting(false);
      setIsSubmitting(false);
    }
  };

  return {
    addGroupModal,
    setAddGroupModal,
    editValues,
    isSubmitting,
    setIsSubmitting,
    selectedLocalIds,
    selectedGroupId,
    setSelectedGroupId,
    setEditValues,
    toggleAddGroupModal,
    handleObjectsSearch,
    handleOnChangeGroupForm,
    addGroupHandler
  };
};
