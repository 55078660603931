import { FC, Fragment, ReactNode, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { Topbar } from "@vilocnv/allsetra-core";
import { useTranslation } from "react-i18next";
import { HistoryClock, ScheduleReportGreyIcon } from "assets/icons";
// import { TopbarLinkText } from "./ReportsTopbar.styled";
import { useAppSelector } from "hooks";
import { selectAccountFeaturesState } from "app/data/selectors";
import { compact } from "lodash";

interface Props {
  title?: string;
  icon?: ReactNode;
  description?: string;
  onClick?: any;
}

const ReportsCard: FC<Props> = ({
  title,
  icon = <></>,
  description,
  onClick
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { features } = useAppSelector(selectAccountFeaturesState);

  return (
    <Box
      sx={{
        border: "1px solid #CED5DB",
        borderRadius: "8px",
        padding: "20px",
        boxShadow: "0 1px 0 0 #1B1F230A",
        textAlign: "center",
        maxHeight: "165px",
        height: "100%",
        cursor: "pointer"
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          width: "48px",
          height: "48px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid #DFE5F4",
          borderRadius: "8px",
          margin: "0 auto"
        }}
      >
        {icon}
      </Box>
      <Box mt={2}>
        <Typography
          sx={{
            fontSize: "18px",
            color: "#1D1F2B",
            fontWeight: 600,
            textAlign: "center",
            lineHeight: "20px"
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box mt={1}>
        <Typography
          sx={{
            fontSize: "14px",
            color: "#323946",
            fontWeight: 400,
            textAlign: "center",
            lineHeight: "16px"
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default ReportsCard;
