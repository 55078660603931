import { types } from "@vilocnv/allsetra-core";
import { isEmpty, sortBy } from "lodash";

export enum ObjectFieldsEnum {
  isHide = 1,
  isTag = 4,
  isDynamicField = 2,
  isBoth = 6
}

export const sanitizeObjectsMetadataForTags = (object: types.IObject) => {
  const metadata = object.metadata;

  if (isEmpty(metadata)) return [];

  return sortBy(
    metadata.filter(
      (item) => item.field.customerPortal === ObjectFieldsEnum.isTag
    ),
    [(o) => o.field.label]
  );
};

export const sanitizeObjectsMetadataForDynamicFields = (
  object: types.IObject
) => {
  const metadata = object.metadata;

  if (isEmpty(metadata)) return [];

  return sortBy(
    metadata.filter(
      (item) =>
        item.field.customerPortal === ObjectFieldsEnum.isDynamicField ||
        item.field.customerPortal === ObjectFieldsEnum.isBoth
    ),
    [(o) => o.field.label]
  );
};

export const transformObjectMetadataValue = (
  value: string,
  translator: any
) => {
  if (value === "True") {
    return translator ? translator("typography.on") : "ON";
  } else if (value === "False") {
    return translator ? translator("typography.off") : "OFF";
  } else {
    return "value";
  }
};

export function createReadOnlyMap(data: any) {
  const readOnlyMap: Record<string, boolean> = {};

  data.metadata.forEach((item: any) => {
    if (item.field && "label" in item.field && "isReadOnly" in item.field) {
      readOnlyMap[item.field.label] = item.field.isReadOnly;
    }
  });

  return readOnlyMap;
}

const fieldMappings: Record<string, string> = {
  objectName: "objectName",
  objectType: "objectType",
  assignedAccounts: "assignedAccounts",
  groups: "groups",
  alarmOwner: "alarmOwner",
  invoiceOwner: "invoiceOwner",
  aNumber: "aNumber",
  multiviewerName: "multiviewerName"
};

export function isFormFieldReadOnly(
  formFieldName: string,
  readOnlyMap: Record<string, boolean>
) {
  const jsonFieldLabel = fieldMappings[formFieldName];
  if (!jsonFieldLabel) {
    return false;
  }

  return readOnlyMap[jsonFieldLabel] === true;
}

export const objectsFormatterForApi = (values: any) => {
  return values.map((item: any) => {
    return {
      fieldUniqueId: item.field.uniqueId,
      value: item.value
    };
  });
};
