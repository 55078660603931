import { Box, styled } from "@mui/material";
import { Field } from "formik";

export const Label = styled("label")({
  display: "flex",
  fontSize: "14px",
  fontWeight: 500,
  margin: "0px",
  flexDirection: "row",
  alignItems: "center",
  color: "#212B33"
});

export const Checkbox = styled(Field)({
  width: "16px",
  height: "16px",
  marginRight: "6px"
});

export const DaysTitle = styled("span")({
  color: "#1D1F2B",
  fontSize: "12px",
  fontWeight: "400"
});

export const ButtonsRow = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(7, auto)",
  marginTop: "4px !important"
});

export const LabelRowBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "15px"
});
