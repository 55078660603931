import { FC } from "react";
import { FormikInputField } from "@vilocnv/allsetra-core";
import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

interface Props {
  values: any;
}

const TimeFilters: FC<Props> = ({ values }) => {
  const theme = useTheme();
  const { t } = useTranslation(["translation", "formFieldsTranslation"]);
  const { setFieldValue } = useFormikContext();

  return (
    // <Stack spacing={2}>
    <Grid container>
      <Grid item xs={12}>
        <Typography
          sx={{ color: "#1D1F2B", fontSize: "12px", fontWeight: "400" }}
        >
          {t("reports.selectTimeRange")}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Typography
            sx={{ color: "#646D7B", fontSize: "14px", fontWeight: "400" }}
          >
            from
          </Typography>
          <FormikInputField
            theme={theme}
            type={"time"}
            name="startTime"
            //   label={t("startTime.label", {
            //     ns: "formFieldsTranslation"
            //   })}
            fullWidth
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <Typography
            ml={1}
            sx={{ color: "#646D7B", fontSize: "14px", fontWeight: "400" }}
          >
            to
          </Typography>
          <FormikInputField
            theme={theme}
            type={"time"}
            name="endTime"
            //   label={t("endTime.label", {
            //     ns: "formFieldsTranslation"
            //   })}
            fullWidth
          />
        </Box>
      </Grid>
    </Grid>
    // </Stack>
  );
};

export default TimeFilters;
