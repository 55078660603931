import { FC, useEffect, useMemo, useState } from "react";
import {
  AccordionLayout,
  FormikSelectField,
  FormikInputField,
  Modal,
  types,
  FormikPlacesSearchField
} from "@vilocnv/allsetra-core";
import { ModalProps, Stack, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import { OBJECT_RIDE_MODES_OPTIONS } from "app/data/helpers";
import {
  getAccountPointsOfInterestsThunk,
  getAllKeysByAccountThunk,
  getGooglePlacesThunk
} from "app/features";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  selectAccountKeysState,
  selectDashboardGooglePlacesState,
  selectDrawerSelectedAccountId
} from "app/data/selectors";
import { useTranslation } from "react-i18next";
import { object } from "yup";

type Props = Omit<ModalProps, "title" | "children"> & {
  customIsSubmitting: boolean;
  objectRide: types.IObjectRide | null;
};

const InnerForm: FC<Props> = ({
  open,
  onClose,
  customIsSubmitting,
  objectRide,
  ...rest
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { googlePredictatedPlacesLoading, googlePredictatedPlaces } =
    useAppSelector(selectDashboardGooglePlacesState);

  const [startOptions, setStartOptions] = useState([]);
  const [endOptions, setEndOptions] = useState([]);

  const {
    resetForm,
    isSubmitting,
    isValid,
    handleSubmit,
    setFieldValue,
    values
  } = useFormikContext<types.IUpdateObjectRide>();

  // Global State
  const accountId = useAppSelector(selectDrawerSelectedAccountId);
  const { accountKeys, loading: accountKeysLoading } = useAppSelector(
    selectAccountKeysState
  );

  const { t } = useTranslation(["translation"]);

  const rideModes = useMemo(() => OBJECT_RIDE_MODES_OPTIONS(t), [t]);

  const debounceFetchPlaces = (searchedText: any) => {
    dispatch(getGooglePlacesThunk(searchedText));
  };

  useEffect(() => {
    dispatch(getAllKeysByAccountThunk(accountId ?? ""));
    dispatch(getAccountPointsOfInterestsThunk(accountId ?? ""));
  }, [accountId]);

  const onCloseHandler = () => {
    // @ts-ignore
    onClose();
    resetForm();
  };

  return (
    <Modal
      open={open}
      onClose={onCloseHandler}
      title={"Edit ride"}
      primaryBtnProps={{
        type: "submit",
        text: t("buttonsText.saveChanges"),
        loading: isSubmitting || customIsSubmitting,
        disabled: !isValid,
        // @ts-ignore
        onClick: handleSubmit
      }}
      secondaryBtnProps={{
        text: t("buttonsText.cancel"),
        onClick: onCloseHandler
      }}
      theme={theme}
      {...rest}
    >
      <Stack spacing={2}>
        <AccordionLayout title="General information" expand>
          <Stack spacing={2}>
            <FormikSelectField
              theme={theme}
              label="Key"
              name="keyId"
              options={accountKeys ?? []}
              optionLabelKey={"label"}
              optionValueKey={"uniqueId"}
              loading={accountKeysLoading}
              fullWidth
            />
            <FormikSelectField
              theme={theme}
              label="Type"
              name="rideMode"
              options={rideModes ?? []}
              optionLabelKey={"label"}
              optionValueKey={"value"}
              required
              fullWidth
            />
            <FormikInputField
              theme={theme}
              label="Comment"
              name="comments"
              multiline
              rows={2}
              fullWidth
            />
          </Stack>
        </AccordionLayout>

        {/* @ts-ignore */}
        {!objectRide.startAddress && !objectRide.correctedStartAddress && (
          <AccordionLayout title="Start of the Ride">
            <Stack spacing={2}>
              <FormikInputField
                theme={theme}
                label="Ride Start Address"
                name="startAddress"
                fullWidth
              />
            </Stack>
          </AccordionLayout>
        )}

        {/* @ts-ignore */}
        {!objectRide.endAddress && !objectRide?.correctedEndAddress && (
          <AccordionLayout title="End of the Ride">
            <Stack spacing={2}>
              <FormikInputField
                theme={theme}
                label="Ride End Address"
                name="endAddress"
                fullWidth
              />
            </Stack>
          </AccordionLayout>
        )}
      </Stack>
    </Modal>
  );
};

export default InnerForm;
